/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PropTypes from 'prop-types';
import SearchInput from '../../UI/Inputs/SearchInputs/SearchInput';
import CancelIcon from '../../../../public/icons/svg/cancel.svg';
import styles from './LocationModal.module.scss';
import Backdrop from '../../UI/Backdrop/Backdrop';

const LocationModal = ({ onClose, onSelect }) => {
  const [place, setPlace] = useState();
  const modal = useRef(null);

  // let locationText = '';
  // if (location && location.city && location.state && location.country) {
  //   locationText = `${location.city}, ${location.state}, ${location.country}`;
  // }

  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (modal.current && !modal.current.contains(event.target)) onClose();
    };

    document.addEventListener('mousedown', handleClickOutsideDropdown);
    document.addEventListener('touchstart', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
      document.removeEventListener('touchstart', handleClickOutsideDropdown);
    };
  });

  const getUserPlace = (data) => {
    if (data && data.terms) {
      const [{ value: city = '' } = {}, { value: state = '' } = {}, { value: country = '' } = {}] = data.terms;
      const locResult = { city, state, country };
      setPlace(locResult);
    }
  };

  const renderOption = (option, onClick) => {
    let filter = option;
    if (option.description) {
      filter = option.description;
    }

    return (
      <button
        className={styles.option}
        type="button"
        key={filter}
        onClick={() => {
          onClick(option);
        }}
      >
        <p>
          {filter}
        </p>
      </button>
    );
  };

  return (
    <div className={styles.locationModal} ref={modal}>
      <header>
        <p>Search Location</p>
        <button type="button" onClick={onClose}>
          <CancelIcon />
        </button>
      </header>
      <p className={styles.ques}>Enter your city or state to show relevant results</p>
      <GooglePlacesAutocomplete
        placeholder="Search Location"
        // initialValue={locationText}
        onSelect={getUserPlace}
        autocompletionRequest={{
          types: ['(cities)'],
        }}
        renderInput={(inputProps) => (
          <div className={styles.search}>
            <SearchInput
              onChange={inputProps.onChange}
              onKeyDown={inputProps.onKeyDown}
              value={inputProps.value}
              placeholder={inputProps.placeholder}
            />
          </div>
        )}
        renderSuggestions={(suggestions, activeSuggestion, onSuggestionSelect) => (
          <div className={styles.options}>
            {activeSuggestion.map(
              (el) => renderOption(el, onSuggestionSelect),
            )}
          </div>
        )}
      />
      {/* <div className={styles.search}>
        <SearchInput
          placeholder="Search Industry"
          onChange={searchQueryChangeHandler}
          onKeyDown={searchQueryChangeHandler}
          value={searchQuery}
        />
      </div>
      {filteredOptions.length ? (
        <div className={styles.options}>
          {filteredOptions.map((option) => renderOption(option, buttonClickHandler))}
        </div>
      ) : null} */}
      <button
        className={styles.submitBtn}
        type="button"
        onClick={() => onSelect(place)}
      >
        Done
      </button>
      <Backdrop showBackdrop zIndex={-1} closeModal={onClose} />
    </div>
  );
};

LocationModal.defaultProps = {
  onClose: () => {},
  onSelect: () => {},
};

LocationModal.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

export default LocationModal;
