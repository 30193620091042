/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Linkify from 'linkifyjs/react';
import CancelIcon from '../../../../../../public/icons/svg/cancel.svg';
import styles from '../GlobalSeach.module.scss';
import Modal from '../../../../UI/Modal/Modal';

const DealSearchCard = ({ detail }) => {
  const [openDetail, setOpenDetail] = useState(false);
  const user = useSelector((state) => state.account.user);

  const viewDealDetail = () => {
    if (user.isPro) {
      setOpenDetail(true);
    }
  };

  const redeemDeal = (post) => {
    if (post.redeem.type === 'link') {
      window.analytics.track('Deal Redeem', {
        deal: post.title,
        link: post.redeem.content,
      });
      window.open(post.redeem.content, '_blank');
      setOpenDetail(false);
    }
  };

  return (
    <>
      {openDetail ? (
        <>
          <Modal
            isOpen={openDetail}
            onClose={() => setOpenDetail(false)}
            maximumWidth="33rem"
          >
            <div className={styles.dealDetailModal}>
              <div className={styles.header}>
                <h3>Deals Detail</h3>
                <button type="button" onClick={() => setOpenDetail(false)}>
                  <CancelIcon />
                </button>
              </div>
              <div className={styles.brand}>
                <img src={detail.image} alt={detail.title} />
                <h3>{detail.deal}</h3>
              </div>
              <Linkify>{detail.subtitle}</Linkify>
              {detail.requirements ? (
                <div className={styles.requirement}>
                  <h3>Requirements</h3>
                  <p>{detail.requirements}</p>
                </div>
              ) : null}
              <div className={styles.btnWrapper}>
                <button
                  type="submit"
                  onClick={() => redeemDeal(detail)}
                  className={styles.submitBtn}
                >
                  Redeem
                </button>
              </div>
            </div>
          </Modal>
        </>
      ) : null}
      <div
        className={styles.DealSearch}
        onKeyDown={viewDealDetail}
        role="presentation"
        onClick={viewDealDetail}
      >
        <div className={styles.content}>
          <div className={styles.dealImage}>
            <img src={detail.image} alt={detail.deal} />
          </div>
          <div>
            <h3 className={styles.title}>{detail.title}</h3>
            <p>{detail.deal}</p>
          </div>
        </div>
        <div>
          <button type="button" onClick={viewDealDetail}>
            View
            {' '}
          </button>
        </div>
      </div>
    </>
  );
};

DealSearchCard.propTypes = {
  detail: PropTypes.func,
};

DealSearchCard.defaultProps = {
  detail: null,
};
export default DealSearchCard;
