/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';

const Checkbox = ({ value, onChange, name }) => (
  <label className={styles.checkbox} htmlFor={name}>
    <input type="checkbox" checked={value} name={name} id={name} onChange={onChange} />
    <span />
  </label>
);

Checkbox.defaultProps = {
  value: false,
  onChange: () => {},
  name: '',
};

Checkbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default Checkbox;
