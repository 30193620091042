/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import styles from './SearchFilter.module.scss';
import ToggleIcon from '../../../../../../public/icons/svg/chevronDown.svg';
import LocationModal from '../../../../new/LocationModal/LocationModal';
import Modal from '../../../../UI/Modal/Modal';
import SelectDropDown from '../../../../new/SelectDropDown/SelectDropDown';
import GlobalSearchContext from '../../../../../contexts/GlobalSearchContext';
import FilterModal from '../../../../new/SelectDropDown/FilterModal';

const SearchFilter = () => {
  const [dropdown, setDropdown] = useState('');
  const {
    industry,
    titles,
    location,
    setIndustry,
    index,
    setTitles,
    setLocation,
    resetFilter,
  } = useContext(GlobalSearchContext);
  const closeModal = () => setDropdown('');

  const handleLocationChange = (loc) => {
    if (loc) {
      setLocation(loc.city);
    }
    setDropdown('');
  };

  const handleTitleSearch = (items) => {
    setTitles([...items]);
  };

  const handleIndustrySearch = (items) => {
    setIndustry(items);
  };

  const tabFilterOptions = [
    [],
    [
      { id: 'location', value: location },
      { value: industry[0], id: 'industry' },
      { value: titles[0], id: 'role' },
    ],
    [
      { id: 'location', value: location },
      { value: industry[0], id: 'industry' },
    ],
    [
      { id: 'location', value: location },
      { value: industry[0], id: 'industry' },
    ],
    [
      { id: 'location', value: location },
      { value: industry[0], id: 'industry' },
    ],
    [],
  ];

  const SearchButton = ({ title, id }) => (
    <button
      type="button"
      onClick={() => setDropdown(id)}
      className={clsx(
        styles.filterItem,
        (location?.length > 0 || titles.length > 0 || industry.length > 0)
          && title !== id
          && styles.active,
      )}
    >
      <span className={styles.text}>{title}</span>
      <ToggleIcon />
    </button>
  );
  return (
    <>
      <div className={styles.container}>
        {(location.length > 0 || titles.length > 0 || industry.length > 0) && (
          <button
            type="button"
            className={styles.clearBtn}
            onClick={resetFilter}
          >
            Clear
          </button>
        )}
        {tabFilterOptions[index].map((item) => (
          <SearchButton
            key={item.id}
            title={item.value || item.id}
            id={item.id}
          />
        ))}
      </div>

      <Modal
        isOpen={dropdown === 'industry'}
        onClose={() => setDropdown('')}
        maximumWidth="20rem"
      >
        <SelectDropDown
          onClose={closeModal}
          onSelect={handleIndustrySearch}
          pickedValue={industry}
          maxLimit={1}
          headerText="Filter By: Interests"
          headerSub="What Interests are you searching for?"
          placeholder="Search Interests"
        />
      </Modal>

      <Modal
        isOpen={dropdown === 'role'}
        onClose={() => setDropdown('')}
        maximumWidth="20rem"
      >
        <FilterModal
          onClose={closeModal}
          maxLimit={1}
          onSelect={handleTitleSearch}
          pickedValue={titles}
          headerText="Filter By: Roles"
          headerSub="What roles are you searching for?"
          placeholder="Filter By: Roles"
        />
      </Modal>

      <Modal
        isOpen={dropdown === 'location'}
        onClose={() => setDropdown('')}
        maximumWidth="24rem"
      >
        <LocationModal
          onClose={() => setDropdown('')}
          onSelect={handleLocationChange}
        />
      </Modal>

    </>
  );
};

export default SearchFilter;
