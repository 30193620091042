/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
// import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import styles from '../GlobalSeach.module.scss';
import GlobalSearchContext from '../../../../../contexts/GlobalSearchContext';
import PeopleSearchCard from './PeopleSearchCard';

const PeopleSearch = ({ setItem }) => {
  const {
    userItems, loading, MyLoader, hasMoreRef, index, handleSearch,
    debouncedSearchTerm, filterString,
  } = useContext(GlobalSearchContext);
  const [initialLoad, setInitialLoad] = useState(true);
  const [nextPage, setNextPage] = useState(1);

  const fetchMore = () => {
    setInitialLoad(false);
    handleSearch(debouncedSearchTerm, filterString, nextPage, setNextPage);
  };

  const renderItem = (items) => {
    const content = index === 0 ? (
      items.map((user) => <PeopleSearchCard key={user.id} detail={user} />)
    ) : (
      <InfiniteScroll
        pageStart={0}
        initialLoad={initialLoad}
        hasMore={hasMoreRef.current}
        loadMore={fetchMore}
        useWindow={false}
      >
        <>
          {items.map((user) => (
            <PeopleSearchCard key={user.id} detail={user} />
          ))}
          {loading && <MyLoader />}
        </>
      </InfiniteScroll>
    );
    if (items.length === 0 && !loading && index !== 0) {
      return <div className={styles.notFound}>No result found</div>;
    }
    return items.length === 0 && loading ? <MyLoader /> : content;
  };

  return (
    <section>
      {index === 0 && userItems.length !== 0 && (
      <header className={styles.GlobalSearch__header}>
        <h4>People</h4>

        <button type="button" className={styles.btnLink} onClick={setItem}>
          View all
        </button>
      </header>
      )}
      <div className={styles.section}>{renderItem(userItems)}</div>
    </section>
  );
};

export default PeopleSearch;
