import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './ProBadge.module.scss';

const ProBadge = ({ variant, float }) => (
  <div className={clsx(styles.badge, styles[variant], float && styles.floatBadge)}>PRO</div>
);
export const floatBadge = (props) => (
  <div className={styles.floatBadge}>{props.children}</div>
);

ProBadge.propTypes = {
  variant: PropTypes.string,
  float: PropTypes.bool,
};

ProBadge.defaultProps = {
  variant: 'tiny',
  float: false,
};

export default ProBadge;
