/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import router from 'next/router';
import Link from 'next/link';
import AddIcon from '../../../../../public/icons/svg/add.svg';
import Modal from '../../../UI/Modal/Modal';
import HomeIconLight from '../../../../../public/icons/svg/navbar/light-home.svg';
import HomeIconDark from '../../../../../public/icons/svg/navbar/dark-home.svg';
import MarketIconLight from '../../../../../public/icons/svg/navbar/Marketplace-dark1.svg';
import MarketIconDark from '../../../../../public/icons/svg/navbar/Marketplace-light1.svg';
import PostIconDark from '../Icons/postIcon-dark.svg';
import RoomIconDark from '../Icons/roomIcon-dark.svg';
import MeetingIconDark from '../Icons/meetingIcon-dark.svg';
import EventIconDark from '../Icons/eventIcon-dark.svg';
import JobIconDark from '../Icons/jobsIcon-dark.svg';
import PostIcon from '../Icons/postIcon.svg';
import RoomIcon from '../Icons/roomIcon.svg';
import MeetingIcon from '../Icons/meetingIcon.svg';
import EventIcon from '../Icons/eventIcon.svg';
import JobIcon from '../Icons/jobsIcon.svg';
import CloseIconLight from '../Icons/closeIconLight.svg';
import CloseIconDark from '../Icons/closeIconDark.svg';
import DiscoverIconLight from './discoverLight.svg';
import DiscoverIconDark from './discoverDark.svg';
import styles from './BottomBar.module.scss';
import InboxIconLight from '../../../../../public/icons/svg/navbar/Inbox-dark1.svg';
import InboxIconDark from '../../../../../public/icons/svg/navbar/Inbox-light1.svg';
import NavBarIcon from '../NavBarIcon/NavBarIcon';
import NewProModal from '../../../feed/Shortcuts/NewProModal/NewProModal';
import ProBadge from '../../../UI/ProBadge/ProBadge';

const BottomBar = () => {
  const [currPathName, setCurrPathName] = useState();
  const user = useSelector((state) => state.account.user);
  const [dropDownId, setDropDownId] = useState('');
  const continueAfterVerify = useRef(null);
  const [activeModal, setActiveModal] = useState('');
  const lightMode = useSelector((state) => state.main.lightMode);

  const createJobHandler = () => {
    if (!user.isPro) {
      continueAfterVerify.current = true;
      setDropDownId('');
      window.analytics.track('Create Job Upgrde PRO Modal Open');
      return setActiveModal('pro');
    }
    window.analytics.track('Create Job Form Open');
    return router.push('/jobs/create');
  };

  const createSection = [
    {
      label: 'Post',
      icon: lightMode ? <PostIcon /> : <PostIconDark />,
      action: () => {
        window.analytics.track('Create Post Form Open');
        router.push('/post/create');
      },
      path: '/post/create',
      show: true,
      desc: 'Create content, ask questions, etc...',
    },
    {
      label: 'Livestream',
      icon: lightMode ? <RoomIcon /> : <RoomIconDark />,
      action: () => {
        window.analytics.track('Create Room Form Open');
        router.push('/room/new');
      },
      path: '/room/create',
      show: user && user.canCreateRooms,
      desc: 'Start a live audio or video room',
    },
    {
      label: 'Meeting',
      icon: lightMode ? <MeetingIcon /> : <MeetingIconDark />,
      action: () => {
        window.analytics.track('Create Meeting Form Open');
        router.push('/meeting/create');
      },
      path: '/meeting/create',
      show: user && user.canCreateRooms,
      desc: 'Create an instant or scheduled meeting',
    },
    {
      label: 'Event',
      icon: lightMode ? <EventIcon /> : <EventIconDark />,
      action: () => {
        window.analytics.track('Create Event Form Open');
        router.push('/event/create');
      },
      path: '/event/create',
      show: user && user.canCreateRooms,
      desc: 'Schedule a Virtual or IRL Event',
    },
    {
      label: 'Job / Gig',
      icon: lightMode ? <JobIcon /> : <JobIconDark />,
      action: () => { createJobHandler(); },
      path: '/jobs/create',
      show: true,
      desc: 'Post a job or gig to find your team',
      showProBadge: true,
    },
  ];

  // useEffect(() => {
  //   if (user.verified && continueAfterVerify.current) {
  //     setTimeout(() => setActiveModal(''), 2000);
  //     createJobHandler();
  //   }
  // }, [user]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrPathName(window.location.pathname);
    }
  }, []);

  const pathFilter = createSection.filter((e) => e.path !== currPathName);

  const createDropDown = pathFilter.map((section) => {
    let link = null;

    if (section.show) {
      if (section.action) {
        link = (
          <button
            key={section.label}
            className={styles.section}
            type="button"
            onClick={section.action}
          >
            <span className={styles.section__icon}>{section.icon}</span>
            <div>
              <p className={styles.section__label}>
                {section.label}
                {section.showProBadge ? <ProBadge variant="small" /> : null}
              </p>
              <p className={styles.section__desc}>{section.desc}</p>
            </div>
          </button>
        );
      } else if (section.path) {
        link = (
          <Link href={section.path} key={section.label}>
            <a className={styles.section}>
              <span className={styles.section__icon}>{section.icon}</span>
              <div>
                <p className={styles.section__label}>{section.label}</p>
                <p className={styles.section__desc}>{section.desc}</p>
              </div>
            </a>
          </Link>
        );
      }
    }

    return link;
  });

  const closeModal = () => {
    setDropDownId('');
  };

  const handleCreateDropdown = () => {
    if (typeof window !== 'undefined' && dropDownId === 'create') {
      window.analytics.track('Create Content Modal Open');
    }
    if (dropDownId !== 'create') {
      setDropDownId('create');
    } else {
      setDropDownId('');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.firstBlock}>
        <NavBarIcon
          iconlight={<HomeIconLight />}
          icondark={<HomeIconDark />}
          name="Home"
          active={currPathName === '/feed'}
          link="/feed"
          isFeed
        />
        <NavBarIcon
          iconlight={<DiscoverIconLight />}
          icondark={<DiscoverIconDark />}
          name="Discover"
          active={currPathName === '/network'}
          link="/network"
          isFeed
        />
      </div>
      <div className={styles.centerButton}>
        <button
          className={clsx({
            [styles.createBtn]: true,
            [styles.activeCreateBtn]: dropDownId === 'create',
          })}
          type="button"
          onClick={handleCreateDropdown}
        >
          <div>
            <AddIcon />
          </div>
        </button>
      </div>
      <div className={styles.secondBlock}>
        <NavBarIcon
          iconlight={<MarketIconLight />}
          icondark={<MarketIconDark />}
          name="Work"
          active={currPathName === '/marketplace'}
          link="/marketplace"
          isFeed
        />
        <NavBarIcon
          iconlight={<InboxIconLight />}
          icondark={<InboxIconDark />}
          name="Inbox"
          active={currPathName === '/messaging'}
          link="/messaging"
          isFeed
        />
      </div>
      <Modal
        isOpen={!!dropDownId}
        maximumWidth="100%"
        topOffSet="10%"
        isFullHeight
        onClose={closeModal}
      >
        <div
          className={`${styles.navModal}${dropDownId === 'create' ? ` ${styles.show}` : ''
            // eslint-disable-next-line indent
            }`}
        >
          <header>
            <p>Create</p>
            <button className={styles.btn} type="button" onClick={closeModal}>
              {lightMode ? <CloseIconLight /> : <CloseIconDark />}
            </button>
          </header>
          <div>{createDropDown}</div>
        </div>
      </Modal>
      <Modal
        isOpen={activeModal === 'pro'}
        onClose={() => setActiveModal('')}
        maximumWidth="30rem"
      >
        <NewProModal closeModal={() => setActiveModal('')} redirect="jobs/create" />
      </Modal>
    </div>
  );
};

export default BottomBar;
