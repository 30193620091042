/* eslint-disable react/prop-types */
// import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';
import Link from 'next/link';
import { useState } from 'react';
import EntreAvatar from '../../../../UI/Avatar/EntreAvatar/EntreAvatar';
import { mdLink } from '../../../../../shared/utilities';
import styles from '../GlobalSeach.module.scss';

const ContentSearchCard = ({ detail }) => {
  const [shouldTrim] = useState(true);
  return (
    <div className={styles.ContentSearch}>
      <Link href={`/profile/${detail.author.id}`} as={`/profile/${detail.author.id}`}>
        <div className={styles.user}>
          <div className={styles.avatar}>
            <EntreAvatar
              isPro={detail.author.isPro}
              src={detail.author.avatar}
              fullName={detail.author.fullName}
            />
          </div>
          <div className={styles.userDetails}>
            <p className={styles.fullName}>{detail.author.fullName}</p>
            <p className={styles.time}>{moment(detail.postDate).fromNow()}</p>
          </div>
        </div>
      </Link>
      <Link href={`/feed/${detail.id}`} as={`/feed/${detail.id}`}>
        <div className={styles.content}>
          <div className={styles.text}>
            <p>
              {shouldTrim ? mdLink(detail.description)
                : mdLink(detail.description)}
            </p>
          </div>
          {detail.imageUrl && (
            <div className={styles.image}>
              <img className={styles.image} alt="Jason Smith" src={detail.imageUrl} />
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default ContentSearchCard;
