/* eslint-disable no-alert, no-console, linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SearchIcon from '../../../../../public/icons/svg/search.svg';
import styles from './SearchInput.module.scss';

const SearchInputs = ({
  placeholder, onChange, value, onKeyDown, inputStyles, inputContainerStyles,
// eslint-disable-next-line arrow-body-style
}) => {
  return (
    <div className={clsx(styles.searchInput, inputContainerStyles)}>
      <button type="button" onClick={() => {}}>
        <SearchIcon />
      </button>
      <input
        onChange={(event) => onChange(event)}
        value={value}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={inputStyles}
      />
    </div>
  );
};

SearchInputs.defaultProps = {
  placeholder: '',
  onChange: () => {},
  value: '',
  onKeyDown: () => {},
  inputStyles: '',
  inputContainerStyles: '',
};

SearchInputs.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputStyles: PropTypes.string,
  inputContainerStyles: PropTypes.string,
};

export default SearchInputs;
