/* eslint-disable react/prop-types */
/* eslint-disable no-alert, no-console, linebreak-style */

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './EntreTabs.module.scss';
import NotificationIndicator from '../../../NotificationIndicator/NotificationIndicator';
import RoomChatNotificationIndicator from '../../../connect/CallRoom/RoomTabs/InRoomChatTab/NotificationIndicator/NotificationIndicator';

const EntreTabs = ({
  tabs,
  fullWidth,
  isManagedOutside,
  curTab,
  curActiveTab,
  tabChangeHandler,
  isAudienceTab,
  isSmall,
  tabAction,
  componentItem,
  SubHeader,
}) => {
  const [tab, setTab] = useState(tabs[0].component);
  const [activeTab, setActiveTab] = useState(0);
  const handleClick = (component, index) => {
    setTab(component);
    setActiveTab(index);
    tabAction(index);
  };

  useEffect(() => {
    if (componentItem) {
      handleClick(componentItem.component, componentItem.index);
    }
  }, [componentItem]);

  return (
    <div
      style={fullWidth ? { maxWidth: 'initial' } : {}}
      className={isAudienceTab ? styles.tabsAudience : styles.tabs}
    >
      <div className={styles.tabs__none}>
        <div
          className={clsx(
            !isAudienceTab && styles.tabs__BtnWrapper,
            isSmall && styles.tabSmall,
            isAudienceTab && styles.tabs__BtnWrapperAudience,
          )}
        >
          {tabs.map((item, index) => (
            <button
              key={item.title}
              className={
              isAudienceTab
                ? clsx(
                  styles.tabs__Btn,
                  {
                    [styles.tabs__btnActive]: index === curActiveTab,
                  },
                  isAudienceTab && styles.tabsAudience__Btn,
                )
                : clsx(
                  styles.tabs__Btn,
                  {
                    [styles.tabs__btnActive]: index === activeTab,
                  },
                  isAudienceTab && styles.tabsAudience__Btn,
                )
            }
              style={isAudienceTab ? { marginTop: '20px' } : null}
              type="button"
              onClick={() => (isManagedOutside
                ? tabChangeHandler(item.component, index)
                : handleClick(item.component, index))}
            >
              <span className={styles.tabs_Title}>{item.title}</span>
              {item.title === 'Room Chat' && (
              <span className={styles.notification}>
                <RoomChatNotificationIndicator />
              </span>
              )}
              {item.title === 'DMs' && (
              <span className={styles.notification}>
                <NotificationIndicator
                  indicatorStyle={styles.indicatorStyle}
                  title="Messages"
                  show
                />
              </span>
              )}
            </button>
          ))}
        </div>
        <div>{SubHeader}</div>

      </div>
      {isManagedOutside ? curTab : tab}
    </div>
  );
};

EntreTabs.propTypes = {
  tabs: PropTypes.arrayOf(Object).isRequired,
  curTab: PropTypes.object,
  fullWidth: PropTypes.bool,
  isManagedOutside: PropTypes.bool,
  isAudienceTab: PropTypes.bool,
  isSmall: PropTypes.bool,
  curActiveTab: PropTypes.number,
  tabChangeHandler: PropTypes.func,
  tabAction: PropTypes.func,
  SubHeader: PropTypes.element,
};

EntreTabs.defaultProps = {
  fullWidth: false,
  isManagedOutside: false,
  isAudienceTab: false,
  isSmall: false,
  curActiveTab: null,
  curTab: null,
  tabChangeHandler: () => {},
  tabAction: () => {},
  SubHeader: null,
};

export default EntreTabs;
