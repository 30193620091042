/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
// import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import Link from 'next/link';
import styles from './GlobalSeach.module.scss';
import GlobalSearchContext from '../../../../contexts/GlobalSearchContext';

const JobSearch = ({ setItem }) => {
  const {
    jobItems, loading, MyLoader, hasMoreRef, index, handleSearch,
    debouncedSearchTerm, filterString,
  } = useContext(GlobalSearchContext);
  const [initialLoad, setInitialLoad] = useState(true);
  const [nextPage, setNextPage] = useState(1);

  const JobSearchCard = ({ detail }) => (
    <Link href={`/jobs/${detail.id}`} as={`/jobs/${detail.id}`}>
      <div className={styles.JobSearch}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h4 className={styles.jobTitle}>{detail.jobTitle}</h4>
            <div className={styles.jobDetails}>
              <p className={styles.jobCompany}>{detail.companyName}</p>
              <div className={styles.jobSalary}>

                {(detail?.amount?.min?.length > 0 || detail?.amount?.max?.length > 0)
              && (
                <>
                  <span>Salary:</span>
                  <span className={styles.jobPay}>
                    {`$${detail?.amount?.min}`}
                    - $
                    {detail?.amount?.max}
                  </span>
                </>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );

  const fetchMore = () => {
    setInitialLoad(false);
    handleSearch(debouncedSearchTerm, filterString, nextPage, setNextPage);
  };

  const renderItem = (items) => {
    const content = index === 0 ? (
      items.map((job) => <JobSearchCard key={job.id} detail={job} />)
    ) : (
      <InfiniteScroll
        pageStart={0}
        initialLoad={initialLoad}
        hasMore={hasMoreRef.current}
        loadMore={fetchMore}
        useWindow={false}
      >
        <>
          {items.map((job) => (
            <JobSearchCard key={job.id} detail={job} />
          ))}
          {loading && <MyLoader />}
        </>
      </InfiniteScroll>
    );
    if (items.length === 0 && !loading && index !== 0) {
      return <div className={styles.notFound}>No result found</div>;
    }
    return items.length === 0 && loading ? <MyLoader /> : content;
  };

  return jobItems.length !== 0
    ? (
      <div className={styles.JobSearch}>
        {index === 0 && jobItems.length !== 0
      && (
      <header className={styles.GlobalSearch__header}>
        <h4>Jobs</h4>
        <button type="button" className={styles.btnLink} onClick={setItem}>View all</button>

      </header>
      )}

        <div className={styles.section}>
          {renderItem(jobItems)}
        </div>
      </div>
    ) : null;
};

export default JobSearch;
