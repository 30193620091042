/* eslint-disable react/prop-types */
// import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import GlobalSearchContext from '../../../../../contexts/GlobalSearchContext';
import styles from '../GlobalSeach.module.scss';
import ContentSearchCard from './ContentSearchCard';

const ContentSearch = ({ setItem }) => {
  const {
    contentItems, loading, MyLoader, hasMoreRef, index, handleSearch,
    debouncedSearchTerm, filterString,
  } = useContext(GlobalSearchContext);
  const [initialLoad, setInitialLoad] = useState(true);
  const [nextPage, setNextPage] = useState(1);

  const fetchMore = () => {
    setInitialLoad(false);
    handleSearch(debouncedSearchTerm, filterString, nextPage, setNextPage);
  };

  const renderItem = (items) => {
    const content = index === 0 ? (
      items.map((post) => <ContentSearchCard key={post.id} detail={post} />)
    ) : (
      <InfiniteScroll
        pageStart={0}
        initialLoad={initialLoad}
        hasMore={hasMoreRef.current}
        loadMore={fetchMore}
        useWindow={false}
      >
        <>
          {items.map((post) => (
            <ContentSearchCard key={post.id} detail={post} />
          ))}
          {loading && <MyLoader />}
        </>
      </InfiniteScroll>
    );
    if (items.length === 0 && !loading && index !== 0) {
      return <div className={styles.notFound}>No result found</div>;
    }
    return items.length === 0 && loading ? <MyLoader /> : content;
  };

  return (
    <section className={styles.contentSearchWrapper}>
      {index === 0 && contentItems.length !== 0
      && (
      <header className={styles.GlobalSearch__header}>
        <h4>Content</h4>
        <button type="button" className={styles.btnLink} onClick={setItem}>View all</button>
      </header>
      )}
      <div className={styles.section}>
        {renderItem(contentItems)}

      </div>
    </section>
  );
};

export default ContentSearch;
