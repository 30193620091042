import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './EntreAvatar.module.scss';
import { getNameInitials } from '../../../../shared/libs/formatters';
import ProBadge from '../../ProBadge/ProBadge';

const EntreAvatar = ({
  src, fullName,
  size, isPro, badgeSize, showEmptyAvatar,
}) => {
  const [imgLink, setImageLink] = useState(src);

  useEffect(() => {
    setImageLink(src);
  }, [src]);

  if (showEmptyAvatar) {
    return (
      <>
        <img
          src="empty_avatar1.png"
          alt={fullName}
          className={styles.Avatar_noBg}
          onError={() => setImageLink(null)}
        />
      </>
    );
  }

  return (
    <div
      className={styles.AvatarWrapper}
      style={
        {
          height: size,
          width: size,
        }
      }
    >
      {imgLink ? (
        <img
          src={imgLink}
          alt={fullName}
          className={styles.Avatar}
          onError={() => setImageLink(null)}
        />
      ) : (
        <div className={clsx(styles.Avatar, [styles.Initials])}>
          {fullName && getNameInitials(fullName)}
        </div>
      )}

      <div className={styles.badgeWrapper}>
        {isPro ? <ProBadge variant={badgeSize} float /> : ''}
      </div>
    </div>
  );
};

EntreAvatar.defaultProps = {
  size: '100%',
  src: null,
  fullName: '',
  isPro: false,
  badgeSize: 'small',
  showEmptyAvatar: false,
};

EntreAvatar.propTypes = {
  size: PropTypes.string,
  src: PropTypes.string,
  fullName: PropTypes.string,
  isPro: PropTypes.bool,
  badgeSize: PropTypes.string,
  showEmptyAvatar: PropTypes.bool,
};

export default EntreAvatar;
