/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert, no-console, linebreak-style */
import React, { useContext } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import styles from './NavBarIcon.module.scss';
import NotificationIndicator from '../../../NotificationIndicator/NotificationIndicator';
import { InboxConnectionsContext } from '../../../../contexts/InboxConnectionsContext';

const NavBarIcon = ({
  icondark, iconlight, name, active, link, isMobile, isFeed,
}) => {
  const { allNofications, messageCounter } = useContext(InboxConnectionsContext) || {};
  const lightMode = useSelector((state) => state.main.lightMode);

  return (
    <Link href={link} as={link}>
      <a
        className={clsx({
          [styles.navBarIcon]: true,
          [styles.active]: active,
          [styles.topNavbar]: isMobile,
          [styles.iconContainer]: isFeed,
        })}
      >
        <div className={styles.mainIcon}>
          <div className={styles.icon}>
            { lightMode ? iconlight : icondark }
          </div>
          {(name === 'Notifications' || name === 'Inbox') && (allNofications > 0 || messageCounter > 0) && <div className={styles.indicator}><NotificationIndicator title={(name === 'Inbox') ? 'Messages' : 'all'} show /></div>}
        </div>
        <p className={styles.navBarIcon__name}>{!isMobile ? name : null}</p>
      </a>
    </Link>
  );
};

NavBarIcon.defaultProps = {
  icondark: null,
  iconlight: null,
  name: null,
  active: false,
  link: null,
  isMobile: false,
  isFeed: false,
};

NavBarIcon.propTypes = {
  icondark: PropTypes.element,
  iconlight: PropTypes.element,
  name: PropTypes.string,
  active: PropTypes.bool,
  link: PropTypes.string,
  isMobile: PropTypes.bool,
  isFeed: PropTypes.bool,
};

export default NavBarIcon;
