import Link from 'next/link';
import PropTypes from 'prop-types';
// import { useRouter } from 'next/router';
import EntreAvatar from '../../../../UI/Avatar/EntreAvatar/EntreAvatar';
import styles from '../GlobalSeach.module.scss';
// import ProfileButton from '../../../components/Profile/ProfileSidebar/ProfileButton';

const PeopleSearchCard = ({ detail }) => (
  <Link href={`/profile/${detail.id}`} as={`/profile/${detail.id}`}>
    <section className={styles.PeopleSearch}>
      <div className={styles.PeopleSearch__userDetails}>
        <div className={styles.PeopleSearch__avatar}>
          <EntreAvatar src={detail.avatar} isPro={detail.isPro} fullName={detail.fullName} />
        </div>
        <div className={styles.PeopleSearch__names}>
          <h4 className={styles.fullName}>{detail.fullName}</h4>
          <p className={styles.title}>
            {detail.titleName ? detail.titleName : null}
            {detail.company ? ` at ${detail.company}` : null}
          </p>
        </div>
      </div>
      {/* <div>
          <ProfileButton profile={detail} />
      </div> */}
    </section>
  </Link>
);
PeopleSearchCard.propTypes = {
  detail: PropTypes.object,
};

PeopleSearchCard.defaultProps = {
  detail: {},
};

export default PeopleSearchCard;
