/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadIndustries } from '../../../../common/data/actions';
import SearchInput from '../../UI/Inputs/SearchInputs/SearchInput';
import CancelIcon from '../../../../public/icons/svg/cancel.svg';
import TickIcon from '../../../../public/icons/svg/tick.svg';
import styles from './SelectDropDown.module.scss';
import LoadingSpinner from '../../UI/LoadingSpinner/LoadingSpinner';
import Backdrop from '../../UI/Backdrop/Backdrop';

const SelectDropDown = ({
  curModal,
  onClose,
  onSelect,
  headerText,
  headerSub,
  placeholder,
  pickedValue,
  hideSearch,
  maxLimit,
}) => {
  const modal = useRef(null);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedIndustries, setSelectedIndustries] = useState(pickedValue);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const industries = useSelector((state) => state.account.industries);
  const token = useSelector((state) => state.auth.token);
  const maxSelection = maxLimit;

  const sortIndustries = () => {
    // eslint-disable-next-line no-nested-ternary
    industries.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
  };

  if (industries && Array.isArray(industries) && industries.length) {
    sortIndustries();
  }

  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (
        curModal === 'Industry'
        && modal.current
        && !modal.current.contains(event.target)
      ) onClose();
    };

    document.addEventListener('mousedown', handleClickOutsideDropdown);
    document.addEventListener('touchstart', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
      document.removeEventListener('touchstart', handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    if (token && industries.length === 0) {
      dispatch(loadIndustries());
    }
  }, [token]);

  useEffect(() => {
    // Only Filters Industry
    if (searchQuery.trim().length === 0) {
      setFilteredOptions(industries);
    } else {
      const filteredArr = [];
      industries.forEach((el) => {
        if (
          el.name
          && el.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          filteredArr.push(el);
        }
      });
      setFilteredOptions(filteredArr);
    }
  }, [searchQuery, industries]);

  const onItemSelect = (name) => {
    if (selectedIndustries.length >= maxSelection) {
      toast.warning(`There is a limit of ${maxSelection} options`, {
        variant: 'warning',
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      return;
    }
    console.log('[Add topic Component]', selectedIndustries);
    setSelectedIndustries((prevSelected) => [...prevSelected, name]);
  };

  const onItemClear = (name) => {
    setSelectedIndustries((prevSelected) => prevSelected.filter((el) => el !== name));
  };

  return (
    <>
      <Backdrop showBackdrop zIndex={-2} closeModal={onClose} />
      <div className={styles.industryModal} ref={modal}>
        <header>
          <p>{headerText}</p>
          <button type="button" onClick={onClose}>
            <CancelIcon />
          </button>
        </header>
        <p className={styles.ques}>{headerSub}</p>
        {!hideSearch && (
          <div className={styles.search}>
            <SearchInput
              placeholder={placeholder}
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </div>
        )}
        <div className={styles.options}>
          {selectedIndustries.length !== 0 && (
            <div className={styles.selected}>
              <div className={styles.optionContainer}>
                {selectedIndustries.map((option) => (
                  <button
                    className={styles.option}
                    type="button"
                    key={option}
                    onClick={() => {
                      onItemClear(option);
                    }}
                  >
                    <p className={styles.active}>{option}</p>
                    <span className={styles.active}>
                      <TickIcon />
                    </span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {filteredOptions.length === 0 ? (
          <div className={styles.loader}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={styles.options}>
            <div className={styles.noSelected}>
              {filteredOptions.map((option) => {
                const selected = selectedIndustries.includes(option.name);
                return (
                  <>
                    {!selected ? (
                      <button
                        className={styles.option}
                        type="button"
                        key={option.name}
                        onClick={() => {
                          if (!selected) {
                            onItemSelect(option.name);
                          } else {
                            onItemClear(option.name);
                          }
                        }}
                      >
                        <p className={selected ? styles.active : ''}>
                          {option.name}
                        </p>
                        <span className={selected ? styles.active : ''}>
                          <TickIcon />
                        </span>
                      </button>
                    ) : null}
                  </>
                );
              })}
            </div>
          </div>
        )}
        <button
          className={styles.submitBtn}
          type="button"
          onClick={() => {
            onClose();
            onSelect(selectedIndustries);
          }}
        >
          Done
        </button>
      </div>
    </>
  );
};

SelectDropDown.defaultProps = {
  onClose: () => {},
  onSelect: () => {},
  headerText: 'Select Interests',
  headerSub: 'What Interests is your content related to?',
  placeholder: 'Search',
  curModal: '',
  pickedValue: [],
  hideSearch: false,
  maxLimit: 3,
};

SelectDropDown.propTypes = {
  onClose: PropTypes.func,
  pickedValue: PropTypes.array,
  onSelect: PropTypes.func,
  headerText: PropTypes.string,
  headerSub: PropTypes.string,
  placeholder: PropTypes.string,
  curModal: PropTypes.string,
  hideSearch: PropTypes.bool,
  maxLimit: PropTypes.number,
};

export default SelectDropDown;
