import React from 'react';
import ContentLoader from 'react-content-loader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ShowSkeleton.module.scss';

const ShowSkeleton = ({ type }) => {
  const lightMode = useSelector((state) => state.main.lightMode);

  const skeletonBgColor = () => {
    if (lightMode) {
      return '#f5f6f7';
    }
    return '#404040';
  };

  const skeletonforegroundColor = () => {
    if (lightMode) {
      return '#eee';
    }
    return '#545c6b';
  };

  const newsLoader = () => (
    <ContentLoader backgroundColor={skeletonBgColor()} foregroundColor={skeletonforegroundColor()}>
      <rect x="20" y="0" rx="5" ry="5" width="50" height="50" />
      <rect x="80" y="3" rx="4" ry="4" width="170" height="10" />
      <rect x="80" y="20" rx="3" ry="3" width="150" height="8" />
      <rect x="80" y="35" rx="3" ry="3" width="150" height="8" />

      <rect x="20" y="70" rx="5" ry="5" width="50" height="50" />
      <rect x="80" y="73" rx="4" ry="4" width="170" height="10" />
      <rect x="80" y="90" rx="3" ry="3" width="150" height="8" />
      <rect x="80" y="105" rx="3" ry="3" width="150" height="8" />
    </ContentLoader>
  );

  const cardLoader = () => (
    <>
      <ContentLoader
        viewBox="0 0 580 550"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="0" y="17" rx="4" ry="4" width="700" height="700" />
      </ContentLoader>
      <br />
      <ContentLoader
        viewBox="0 0 580 550"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="0" y="17" rx="4" ry="4" width="700" height="700" />
      </ContentLoader>
    </>
  );

  const mediumCardLoader = () => (
    <>
      <ContentLoader
        viewBox="0 0 580 250"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="0" y="17" rx="5" ry="5" width="290" height="1200" />
        <rect x="300" y="17" rx="5" ry="5" width="290" height="1200" />
      </ContentLoader>
      <ContentLoader
        viewBox="0 0 580 250"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="0" y="17" rx="5" ry="5" width="290" height="1200" />
        <rect x="300" y="17" rx="5" ry="5" width="290" height="1200" />
      </ContentLoader>
      <ContentLoader
        viewBox="0 0 580 250"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="0" y="17" rx="5" ry="5" width="290" height="1200" />
        <rect x="300" y="17" rx="5" ry="5" width="290" height="1200" />
      </ContentLoader>
    </>
  );

  const menuLoader = () => (
    <>
      <ContentLoader
        speed={1}
        width={50}
        height={30}
        viewBox="0 0 50 30"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
      </ContentLoader>
    </>
  );

  const peopleLoader = () => (
    <>
      <ContentLoader
        viewBox="0 0 580 220"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="0" y="17" rx="5" ry="5" width="180" height="1200" />
        <rect x="200" y="17" rx="5" ry="5" width="180" height="1200" />
        <rect x="400" y="17" rx="5" ry="5" width="180" height="1200" />
      </ContentLoader>
    </>
  );

  const upvoteLoader = () => (
    <ContentLoader
      speed={2}
      width={150}
      height={20}
      viewBox="0 0 150 20"
      backgroundColor={skeletonBgColor()}
      foregroundColor={skeletonforegroundColor()}
    >
      <rect x="0" y="0" rx="1" ry="1" width="400" height="400" />
    </ContentLoader>
  );

  const marketNews = () => (
    <ContentLoader
      backgroundColor={skeletonBgColor()}
    >
      <rect x="0" y="17" rx="5" ry="5" width="110" height="100" />
      <rect x="120" y="17" rx="5" ry="5" width="110" height="100" />
    </ContentLoader>
  );

  const contentDetail = () => (
    <ContentLoader
      viewBox="0 0 580 80"
      backgroundColor={skeletonBgColor()}
      foregroundColor={skeletonforegroundColor()}
    >
      <rect x="68" y="8" rx="4" ry="4" width="500" height="80" />
      <circle cx="25" cy="25" r="25" />
    </ContentLoader>
  );

  const roomsLoader = () => (
    <>
      <ContentLoader
        viewBox="0 0 580 150"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="0" y="17" rx="4" ry="4" width="700" height="700" />
      </ContentLoader>
    </>
  );

  const profileInfoLoader = () => (
    <>
      <ContentLoader height={80} backgroundColor={skeletonBgColor()}>
        <rect x="68" y="8" rx="3" ry="3" width="120" height="10" />
        <rect x="68" y="30" rx="3" ry="3" width="90" height="10" />
        <circle cx="30" cy="30" r="30" />
      </ContentLoader>
    </>
  );

  const globalSearchLoader = () => (
    <>
      <ContentLoader
        viewBox="0 0 400 50"
        backgroundColor={skeletonBgColor()}
        foregroundColor={skeletonforegroundColor()}
      >
        <rect x="10" y="17" rx="1" ry="4" width="200" height="13" />
        <rect x="10" y="40" rx="1" ry="3" width="150" height="10" />
      </ContentLoader>
    </>
  );

  const ChatListCardSkeleton = () => (
    <>
      <SkeletonTheme color={skeletonBgColor()} highlightColor={skeletonforegroundColor()}>
        <div className={styles.chat}>
          <span className={styles.activeSpan} />
          <div className={styles.chat_userIcon}>
            <Skeleton circle width={60} height={60} />
          </div>
          <div className={styles.chat_details}>
            <div className={styles.top}>
              <p className={styles.name}>
                <Skeleton width={200} height={19} />
              </p>
              <p className={styles.time}>
                <Skeleton width={65} height={15} />
              </p>
            </div>
            <div className={styles.bottom}>
              <p className={styles.lastMessage}>
                <Skeleton width={65} height={18} />
              </p>
              <div>
                <Skeleton circle width={16} height={16} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  );

  const titleLoader = () => (
    <>
      <SkeletonTheme color={skeletonBgColor()} highlightColor={skeletonforegroundColor()}>
        <Skeleton width={300} height={30} />
      </SkeletonTheme>
    </>
  );

  const messageGroupLoader = () => (
    <>
      <SkeletonTheme color={skeletonBgColor()} highlightColor={skeletonforegroundColor()}>
        <Skeleton width="7rem" height="1rem" baseColor="#F2F2F2" />
      </SkeletonTheme>
    </>
  );

  const messageGroupLoader2 = () => (
    <>
      <SkeletonTheme color={skeletonBgColor()} highlightColor={skeletonforegroundColor()}>
        <Skeleton width="2.4rem" height="2.4rem" baseColor="#F2F2F2" circle />
      </SkeletonTheme>
    </>
  );

  const cardHeaderLoader = () => (
    <>
      <SkeletonTheme color={skeletonBgColor()} highlightColor={skeletonforegroundColor()}>
        <Skeleton width="100%" height="10px" borderRadius="3.33803px" />
      </SkeletonTheme>
    </>
  );

  const navBarLoader = () => (
    <>
      <SkeletonTheme color={skeletonBgColor()} highlightColor={skeletonforegroundColor()}>
        <Skeleton circle height={30} width={30} />
      </SkeletonTheme>
    </>
  );

  const connectionCardLoader = () => (
    <>
      <SkeletonTheme color={skeletonBgColor()} highlightColor={skeletonforegroundColor()}>
        <Skeleton width="150px" height={15} />
      </SkeletonTheme>
    </>
  );

  return (
    <>
      {/* TODO: REFACTOR */}
      {type === 'news' ? (
        newsLoader()
      ) : null}
      {type === 'card' ? (
        cardLoader()
      ) : null}
      {type === 'menu' ? (
        menuLoader()
      ) : null}
      {type === 'upvote' ? (
        upvoteLoader()
      ) : null}
      {type === 'marketNews' ? (
        marketNews()
      ) : null}
      {type === 'mediumCard' ? (
        mediumCardLoader()
      ) : null}
      {type === 'contentDetail' ? (
        contentDetail()
      ) : null}
      {type === 'rooms' ? (
        roomsLoader()
      ) : null}
      {type === 'profileInfo' ? (
        profileInfoLoader()
      ) : null}
      {type === 'people' ? (
        peopleLoader()
      ) : null}
      {type === 'globalSearch' ? (
        globalSearchLoader()
      ) : null}
      {type === 'chatList' ? (
        ChatListCardSkeleton()
      ) : null }
      {type === 'title' ? (
        titleLoader()
      ) : null }
      {type === 'messageGroup' ? (
        messageGroupLoader()
      ) : null }
      {type === 'messageGroup2' ? (
        messageGroupLoader2()
      ) : null }
      {type === 'cardHeader' ? (
        cardHeaderLoader()
      ) : null }
      {type === 'navbar' ? (
        navBarLoader()
      ) : null }
      {type === 'connectionCard' ? (
        connectionCardLoader()
      ) : null }
    </>
  );
};

ShowSkeleton.propTypes = {
  type: PropTypes.string,
};

ShowSkeleton.defaultProps = {
  type: null,
};

export default ShowSkeleton;
