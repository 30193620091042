/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import styles from '../GlobalSeach.module.scss';
import GlobalSearchContext from '../../../../../contexts/GlobalSearchContext';
import DealSearchCard from './DealSearchCard';

const DealSearch = ({ setItem }) => {
  const {
    dealItems,
    loading,
    MyLoader,
    hasMoreRef,
    index,
    handleSearch,
    debouncedSearchTerm,
    filterString,
  } = useContext(GlobalSearchContext);
  const [nextPage, setNextPage] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    console.log('dealItems updated', dealItems);
  }, [dealItems]);

  const fetchMore = () => {
    setInitialLoad(false);
    handleSearch(debouncedSearchTerm, filterString, nextPage, setNextPage);
  };

  const renderItem = (items) => {
    const content = index === 0 ? (
      items.map((deal) => <DealSearchCard key={deal.title} detail={deal} />)
    ) : (
      <InfiniteScroll
        pageStart={0}
        initialLoad={initialLoad}
        hasMore={hasMoreRef.current}
        loadMore={fetchMore}
        useWindow={false}
      >
        <>
          {items.map((deal) => (
            <DealSearchCard key={deal.title} detail={deal} />
          ))}
          {loading && <MyLoader />}
        </>
      </InfiniteScroll>
    );
    if (items.length === 0 && !loading && index !== 0) {
      return <div className={styles.notFound}>No result found</div>;
    }
    return items.length === 0 && loading ? <MyLoader /> : content;
  };

  return (
    <div className={styles.section}>
      {index === 0 && dealItems.length !== 0 && (
      <header className={styles.GlobalSearch__header}>
        <h4>Deals</h4>
        <button type="button" className={styles.btnLink} onClick={setItem}>
          View all
        </button>
      </header>
      )}

      {renderItem(dealItems)}
    </div>
  );
};

DealSearch.propTypes = {
  setItem: PropTypes.func,
};

DealSearch.defaultProps = {
  setItem: null,
};
export default DealSearch;
