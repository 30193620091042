/* eslint-disable indent */
/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import SearchIcon from '../../../../public/icons/svg/search.svg';
import AddIcon from '../../../../public/icons/svg/add.svg';
import Modal from '../../UI/Modal/Modal';
import HomeIconLight from '../../../../public/icons/svg/navbar/light-home.svg';
import HomeIconDark from '../../../../public/icons/svg/navbar/dark-home.svg';
import MarketIconLight from '../../../../public/icons/svg/navbar/Marketplace-dark1.svg';
import MarketIconDark from '../../../../public/icons/svg/navbar/Marketplace-light1.svg';
import NetworkIconLight from '../../../../public/icons/svg/navbar/Network-light2.svg';
import NetworkIconDark from '../../../../public/icons/svg/navbar/Network-dark2.svg';
import CalendarIconLight from '../../../../public/icons/svg/navbar/Calendar-dark1.svg';
import CalendarIconDark from '../../../../public/icons/svg/navbar/Calendar-light1.svg';
import InboxIconLight from '../../../../public/icons/svg/navbar/Inbox-dark1.svg';
import InboxIconDark from '../../../../public/icons/svg/navbar/Inbox-light1.svg';
import NotificationsIconLight from '../../../../public/icons/svg/navbar/Notifications-light1.svg';
import NotificationsIconDark from '../../../../public/icons/svg/navbar/Notifications-dark1.svg';
import PostIconDark from './Icons/postIcon-dark.svg';
import RoomIconDark from './Icons/roomIcon-dark.svg';
import MeetingIconDark from './Icons/meetingIcon-dark.svg';
import EventIconDark from './Icons/eventIcon-dark.svg';
import JobIconDark from './Icons/jobsIcon-dark.svg';
import PostIcon from './Icons/postIcon.svg';
import RoomIcon from './Icons/roomIcon.svg';
import MeetingIcon from './Icons/meetingIcon.svg';
import EventIcon from './Icons/eventIcon.svg';
import JobIcon from './Icons/jobsIcon.svg';
import ClearIcon from '../../../../public/icons/svg/cancel.svg';
import ArrowDown from '../../../../public/icons/svg/arrow-down.svg';
import NavBarIcon from './NavBarIcon/NavBarIcon';

import {
  logOut,
  setFilters,
  switchPalleteColor,
} from '../../../../common/data/actions';

import styles from './DesktopNavBar.module.scss';
import CloseIconLight from './Icons/closeIconLight.svg';
import CloseIconDark from './Icons/closeIconDark.svg';
import GlobalSearch from './GlobalSearch/GlobalSearch';
import { GlobalSearchContextProvider } from '../../../contexts/GlobalSearchContext';
import Checkbox from '../../UI/Checkbox/Checkbox';
import ShowSkeleton from '../../UI/Skeleton/ShowSkeleton';
import NewProModal from '../../feed/Shortcuts/NewProModal/NewProModal';
import ProBadge from '../../UI/ProBadge/ProBadge';
import DmChatStore from '../../../../common/mobx/stores/DmChatStore';
import PhoneVerification from '../../Inbox/Messages/PhoneVerification/PhoneVerification';
import newApi from '../../../../common/api/newApi';
import LoadingSpinner from '../../UI/LoadingSpinner/LoadingSpinner';
import EntreAvatar from '../../UI/Avatar/EntreAvatar/EntreAvatar';

const DesktopNavBar = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const user = useSelector((state) => state.account.user);
  const [searchInputState = searchQuery, setSearchInputState] = useState();
  const [dropDownId, setDropDownId] = useState('');
  const [currPathName, setCurrPathName] = useState('/feed');
  const [activeModal, setActiveModal] = useState('');
  const lightMode = useSelector((state) => state.main.lightMode);
  const continueAfterVerify = useRef(null);
  const openChatAfterVerify = useRef(null);
  const [loading, setLoading] = useState(false);

  const messageSupportHandler = async () => {
    if (!user?.verified) {
      openChatAfterVerify.current = true;
      return setActiveModal('phone');
    }
    // used for dev env
    let supportUser = { id: 'm5UcSvMbq8ccQC1pxaDVzTsF7yL2' };
    if (process.env.ENV === 'production') {
      // Production support account
      const supportUserData = await newApi.get('user/username/support');
      supportUser = supportUserData.data;
    }
    if (!supportUser) {
      throw new Error();
    }
    if (window.innerWidth <= 900) {
      sessionStorage.setItem('chatDetails', JSON.stringify({ isChatId: false, profile: supportUser }));
      router.push('/messaging');
    } else {
      await DmChatStore.createChat(null, [supportUser]);
      DmChatStore.setShowQuickChatView(true);
    }
    openChatAfterVerify.current = null;
    return false;
  };

  const profileMenuSection = [
    { label: 'My Profile', path: `/profile/${user && user.username}` },
    { label: 'Billing', path: '/billing' },
    { label: 'Recordings', path: '/recordings', hidden: user && !user.isPro },
    // { label: 'Pro Account', path: '/entre-pro-account', hidden: (user && !user.isPro) },
    //  { label: 'About', path: '/about' },
    { label: 'Admin', path: '/admin', hidden: user && !user.isAdmin },
    { label: 'Support', action: () => messageSupportHandler(), loader: loading ? <LoadingSpinner size="small" /> : null },
    { label: 'Settings', path: '/settings' },
    { label: 'Blog', path: '/blog' },
    { label: 'Help Center', path: 'https://www.entre.link/help' },
    { component: <hr key="hr" />, hideInDrawer: true },
    { label: 'Dark Mode', action: switchPalleteColor(), checkbox: true },
    { component: <hr key="hr2" />, hideInDrawer: true },
    { label: 'Log Out', action: logOut() },
  ].filter((s) => !s.hidden);

  const createJobHandler = () => {
    if (!user.isPro) {
      continueAfterVerify.current = true;
      setDropDownId('');
      window.analytics.track('Create Job Upgrde PRO Modal Open');
      return setActiveModal('pro');
    }
    window.analytics.track('Create Job Form Open');
    return router.push('/jobs/create');
  };

  // useEffect(() => {
  //   if (user.isPro && continueAfterVerify.current) {
  //     setTimeout(() => setActiveModal(''), 2000);
  //     createJobHandler();
  //   }
  // }, [user]);

  const createSection = [
    {
      label: 'Post',
      icon: lightMode ? <PostIcon /> : <PostIconDark />,
      action: () => {
        window.analytics.track('Create Post Form Open');
        router.push('/post/create');
      },
      path: '/post/create',
      show: true,
      desc: 'Create content, ask questions, etc...',
    },
    {
      label: 'Livestream',
      icon: lightMode ? <RoomIcon /> : <RoomIconDark />,
      action: () => {
        window.analytics.track('Create Room Form Open');
        router.push('/room/new');
      },
      path: '/room/create',
      show: user && user.canCreateRooms,
      desc: 'Start a public Livestream',
    },
    {
      label: 'Meeting',
      icon: lightMode ? <MeetingIcon /> : <MeetingIconDark />,
      action: () => {
        window.analytics.track('Create Meeting Form Open');
        router.push('/meeting/create');
      },
      path: '/meeting/create',
      show: user && user.canCreateRooms,
      desc: 'Create an instant or scheduled meeting',
    },
    {
      label: 'Event',
      icon: lightMode ? <EventIcon /> : <EventIconDark />,
      action: () => {
        window.analytics.track('Create Event Form Open');
        router.push('/event/create');
      },
      path: '/event/create',
      show: user && user.canCreateRooms,
      desc: 'Schedule a Virtual or IRL Event',
    },
    {
      label: 'Job / Gig',
      icon: lightMode ? <JobIcon /> : <JobIconDark />,
      action: () => { createJobHandler(); },
      path: '/jobs/create',
      show: true,
      desc: 'Post a job or gig to find your team',
      showProBadge: true,
    },
    // {
    //   label: 'Pitch',
    //   icon: <PitchIcon />,
    //   action: () => router.push('/pitch/create'),
    //   show: true,
    //   desc: 'Post your startup for a chance to win',
    // },
  ];

  useEffect(() => {
    if (typeof window !== 'undefined' && dropDownId === 'create') {
      window.analytics.track('Create Content Modal Open');
    }
    if (dropDownId === 'search') {
      document.body.classList.add('disable-scroll');
    }
    return () => document.body.classList.remove('disable-scroll');
  }, [dropDownId]);

  const inputChangeHandler = (event) => {
    setSearchInputState(event.target.value);
    setDropDownId('search');
    dispatch(setFilters({ searchQuery: event.target.value }));
  };

  const inputFocusHandler = () => {
    setDropDownId('search');
  };

  const linkClickHandler = (event, section) => {
    setLoading(true);
    event.preventDefault();
    if (section.path) {
      if (
        section.path.startsWith('https')
        || section.path.startsWith('mailto:')
      ) {
        window.open(section.path, '_blank');
      } else {
        router.push(section.path);
      }
    } else if (section.action !== null) {
      dispatch(section.action);
    }
    setDropDownId('');
    setLoading(false);
  };

  const searchHandler = (event, searchIconClicked) => {
    if (searchIconClicked || event.key === 'Enter') {
      setDropDownId('search');
      if (event?.target?.value) {
        dispatch(setFilters({ searchQuery: event.target.value }));
      }
    } else if (event.key === 'Escape') {
      setDropDownId('');
      setSearchInputState('');
    }
  };

  const closeModal = () => {
    setDropDownId('');
  };

  useEffect(() => {
    setDropDownId('');
    setSearchInputState('');
    dispatch(setFilters({ searchQuery: '' }));
  }, [router]);

  useEffect(() => {
    if (user?.verified && openChatAfterVerify.current) {
      setTimeout(() => setActiveModal(''), 2000);
      messageSupportHandler();
    }
  }, [user]);

  // hide current page on create navbar menu
  const pathFilter = createSection.filter((e) => e.path !== currPathName);

  const createDropDown = pathFilter.map((section) => {
    let link = null;

    if (section.show) {
      if (section.action) {
        link = (
          <button
            key={section.label}
            className={styles.section}
            type="button"
            onClick={section.action}
          >
            <span className={styles.section__icon}>{section.icon}</span>
            <div>
              <p className={styles.section__label}>
                {section.label}
                {section.showProBadge ? <ProBadge variant="big" /> : null}
              </p>
              <p className={styles.section__desc}>{section.desc}</p>
            </div>
          </button>
        );
      } else if (section.path) {
        link = (
          <Link href={section.path} key={section.label}>
            <a className={styles.section}>
              <span className={styles.section__icon}>{section.icon}</span>
              <div>
                <p className={styles.section__label}>{section.label}</p>
                <p className={styles.section__desc}>{section.desc}</p>
              </div>
            </a>
          </Link>
        );
      }
    }

    return link;
  });

  const profileMenuDropDown = profileMenuSection.map((section) => {
    if (section.component) {
      return section.component;
    }
    return (
      <a
        href={section.path}
        role="button"
        tabIndex={0}
        key={section.label}
        onKeyDown={(event) => linkClickHandler(event, section)}
        onClick={(event) => linkClickHandler(event, section)}
      >
        {section.checkbox ? (
          <>
            <span>
              <Checkbox
                value={!lightMode}
                onChange={() => {
                  switchPalleteColor();
                }}
                name="pallete-checkbox"
              />
            </span>
          </>
        ) : null}
        <p>{section.label}</p>
        {section.label === 'Support' ? <div className={styles.loaderSupport}>{section.loader}</div> : null}
      </a>
    );
  });
  const profileDropdown = () => {
    if (dropDownId !== 'menus') {
      setDropDownId('menus');
    } else {
      setDropDownId('');
    }
  };

  const handleCreateDropdown = () => {
    if (dropDownId !== 'create') {
      setDropDownId('create');
    } else {
      setDropDownId('');
    }
  };

  const createCreateSection = () => (
    <span className={styles.create}>
      <button
        type="button"
        onClick={handleCreateDropdown}
        style={{ margin: 0, marginLeft: 2 }}
        className={clsx({
          [styles.createBtnLM]: lightMode,
          [styles.createBtnDM]: !lightMode,
          [styles.activeCreateBtn]: dropDownId === 'create',
        })}
      >
        <div className={styles.create__image}>
          <AddIcon />
        </div>
        <span className={styles.create__text}> Create</span>
      </button>
    </span>
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrPathName(window.location.pathname);
    }
  }, []);

  const isInboxTab = window.location.pathname.split('/').includes('messaging');
  return (
    <>
      <div style={{ height: isInboxTab ? '3.4rem' : 65 }} />
      <div className={styles.navBar}>
        <div className={styles.navBar__wrapper}>
          <Link href="/feed?section=for-you">
            <a className={styles.logo}>
              {lightMode ? (
                <Image
                  src={user && user.isPro ? '/logo-pro.png' : '/logo.png'}
                  alt="Entre"
                  width={user && user.isPro ? 127 : 82}
                  height={24}
                />
              ) : (
                <Image
                  src={
                    user && user.isPro ? '/logo-pro_dark.png' : '/logo_dark.png'
                  }
                  alt="Entre"
                  width={user && user.isPro ? 127 : 82}
                  height={24}
                />
              )}
            </a>
          </Link>
          <div className={styles.navBar__items}>
            {/* SEARCH SECTION */}
            <div className={styles.input}>
              <button
                className={styles.searcIcon}
                type="button"
                onClick={
                  searchInputState ? () => searchHandler(null, true) : null
                }
              >
                <SearchIcon />
              </button>
              <input
                onChange={inputChangeHandler}
                onFocus={inputFocusHandler}
                value={searchInputState}
                onKeyDown={searchHandler}
                type="search"
                placeholder="Search Something"
              />
              {searchInputState ? (
                <button
                  type="button"
                  className={styles.clearIcon}
                  onClick={() => {
                    dispatch(setFilters({ searchQuery: '' }));
                    setSearchInputState('');
                    setDropDownId('');
                  }}
                >
                  <ClearIcon />
                </button>
              ) : null}
              {dropDownId === 'search' && (
                <div className={`${styles.globalSearch}`}>
                  <GlobalSearchContextProvider>
                    <GlobalSearch />
                  </GlobalSearchContextProvider>
                </div>
              )}
            </div>
            {/* SEARCH SECTION */}
            <div className={styles.pagesShortcut}>
              <NavBarIcon
                iconlight={<HomeIconLight />}
                icondark={<HomeIconDark />}
                name="Home"
                active={currPathName === '/feed'}
                link="/feed"
                isFeed
              />
              <div className={styles.mobile__hide}>
                <NavBarIcon
                  iconlight={<CalendarIconLight />}
                  icondark={<CalendarIconDark />}
                  name="Calendar"
                  active={currPathName === '/calendar'}
                  link="/calendar"
                />
              </div>
              <NavBarIcon
                iconlight={<NetworkIconLight />}
                icondark={<NetworkIconDark />}
                name="Network"
                active={currPathName === '/network'}
                link="/network"
                isFeed
              />
              <div className={styles.mobile__create}>
                {createCreateSection()}
              </div>
              <NavBarIcon
                iconlight={<MarketIconLight />}
                icondark={<MarketIconDark />}
                name="Marketplace"
                active={currPathName === '/marketplace'}
                link="/marketplace"
                isFeed
              />
              <NavBarIcon
                iconlight={<InboxIconLight />}
                icondark={<InboxIconDark />}
                name="Inbox"
                active={currPathName === '/messaging'}
                link="/messaging"
                isFeed
              />
              <div className={styles.mobile__hide}>
                <NavBarIcon
                  iconlight={<NotificationsIconLight />}
                  icondark={<NotificationsIconDark />}
                  name="Notifications"
                  active={currPathName === '/notifications'}
                  link="/notifications"
                />
              </div>
              <div className={styles.desktop__create}>
                {createCreateSection()}
              </div>
            </div>

            <span className={styles.userIcon}>
              <button
                type="button"
                className={styles.wrapper}
                onClick={() => profileDropdown()}
              >
                {user ? (
                  <EntreAvatar fullName={user?.fullName} src={user?.avatar} />
                ) : (
                  <ShowSkeleton type="navbar" />
                )}
                <ArrowDown
                  className={`${styles.dropdownArrow} ${styles.mobile__hide}`}
                />
              </button>
              <div
                className={`${styles.dropDownProfile}${dropDownId === 'menus' ? ` ${styles.show}` : ''
                  }`}
              >
                {profileMenuDropDown}
              </div>
            </span>
          </div>
        </div>
      </div>
      <Modal
        isOpen={activeModal === 'phone'}
        onClose={() => setActiveModal('phone')}
        maximumWidth="30rem"
        disableOutsideClick={!user?.verified}
      >
        <PhoneVerification onClose={() => setActiveModal('')} />
      </Modal>
      <Modal
        isOpen={!!dropDownId}
        maximumWidth="450px"
        topOffSet="10%"
        onClose={closeModal}
      >
        <div
          className={`${styles.navModal}${dropDownId === 'create' ? ` ${styles.show}` : ''
            }`}
        >
          <header>
            <p>Create</p>
            <button className={styles.btn} type="button" onClick={closeModal}>
              {lightMode ? <CloseIconLight /> : <CloseIconDark />}
            </button>
          </header>
          <div>{createDropDown}</div>
        </div>
      </Modal>

      <Modal
        isOpen={activeModal === 'pro'}
        onClose={() => setActiveModal('')}
        maximumWidth="30rem"
      >
        <NewProModal
          closeModal={() => setActiveModal('')}
          redirect="jobs/create"
          analytics="Create Job Upgrade Pro Modal Success"
        />
      </Modal>
    </>
  );
};

export default DesktopNavBar;
