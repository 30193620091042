/* eslint-disable no-console */
/* eslint-disable react/jsx-closing-tag-location */
import router from 'next/router';
import { useContext, useEffect, useState } from 'react';
import EntreTabs from '../../../Onboardings/Auth/EntreTabs/EntreTabs';
import GlobalSearchContext, {
} from '../../../../contexts/GlobalSearchContext';
import ContentSearch from './ContentSearch/ContentSearch';
import DealSearch from './DealSearch/DealSearch';
import EventSearch from './EventSearch';
import styles from './GlobalSeach.module.scss';
import JobSearch from './JobSearch';
import PeopleSearch from './PeopleSearch/PeopleSearch';
import SearchFilter from './SearchFilter/SearchFilter';
// import SearchContext from '../../../contexts/SearchContext';

const GlobalSearch = () => {
  const { setIndex, setCurrTab } = useContext(GlobalSearchContext);
  const [item, setItem] = useState();
  let searchComponents;
  const updateTab = (idx) => {
    setItem(searchComponents[idx]);
    setIndex(idx);
  };

  useEffect(() => {
    if (router.pathname === '/network') {
      updateTab(1);
    }
    if (router.pathname === '/calendar') {
      updateTab(3);
    }
    if (router.pathname === '/marketplace') {
      updateTab(4);
    }
    if (router.query.section === 'deals') {
      updateTab(5);
    }
  }, [router]);

  searchComponents = [
    {
      title: 'All',
      component: (
        <div className={styles.section}>
          <PeopleSearch setItem={() => updateTab(1)} />
          <ContentSearch setItem={() => updateTab(2)} />
          <EventSearch setItem={() => updateTab(3)} />
          <JobSearch setItem={() => updateTab(4)} />
          <DealSearch setItem={() => updateTab(5)} />
        </div>
      ),
    },
    {
      title: 'People',
      component: <PeopleSearch />,
      index: 1,
    },
    {
      title: 'Content',
      component: <ContentSearch />,
      index: 2,
    },
    {
      title: 'Events',
      component: <EventSearch />,
      index: 3,
    },
    {
      title: 'Jobs',
      component: <JobSearch />,
      index: 4,
    },
    {
      title: 'Deals',
      component: <DealSearch />,
      index: 5,
    },
  ];

  const tabChangeHandler = (component, idx) => {
    setCurrTab(component);

    if (idx === 0) {
      setCurrTab(searchComponents[0].component);
    }
  };

  useEffect(() => {
    tabChangeHandler(searchComponents[0].component, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <EntreTabs
        isSmall
        componentItem={item}
        tabs={searchComponents}
        tabAction={setIndex}
        SubHeader={<SearchFilter />}
      />
    </div>
  );
};

export default GlobalSearch;
