export function locationString(location = {}) {
  const locationArr = [];
  if (location && location.city) locationArr.push(location.city);
  if (location && location.state) locationArr.push(location.state);
  if (location && location.country) locationArr.push(location.country);
  return locationArr.join(', ');
}

export function deletemeWhenThereIsAnotherFunctionHere() {}

export const getNameInitials = (nameString) => {
  const fullName = nameString.split(' ');
  if (fullName.length > 1) {
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  }
  return nameString.charAt(0).toUpperCase();
};
