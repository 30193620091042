import { observer } from 'mobx-react-lite';
import React from 'react';
import RoomChatStore from '../../../../../../../common/mobx/stores/RoomChatStore';
import styles from './NotificationIndicator.module.scss';

const NotificationIndicator = () => {
  const { unreadMessages } = RoomChatStore;

  return !!unreadMessages && <span className={styles.indicator} />;
};

export default observer(NotificationIndicator);
