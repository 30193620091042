/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadTitles } from '../../../../common/data/actions';
import SearchInput from '../../UI/Inputs/SearchInputs/SearchInput';
import CancelIcon from '../../../../public/icons/svg/cancel.svg';
import TickIcon from '../../../../public/icons/svg/tick.svg';
import styles from './SelectDropDown.module.scss';
import LoadingSpinner from '../../UI/LoadingSpinner/LoadingSpinner';
import Backdrop from '../../UI/Backdrop/Backdrop';

const SelectDropDown = ({
  curModal, onClose, onSelect,
  headerText, headerSub, placeholder, pickedValue, hideSearch, maxLimit,
}) => {
  const modal = useRef(null);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedIndustries, setSelectedIndustries] = useState(pickedValue);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const titles = useSelector((state) => state.account.titles);
  const token = useSelector((state) => state.auth.token);
  const maxSelection = maxLimit;

  // eslint-disable-next-line no-nested-ternary
  titles.sort((a, b) => ((a > b) ? 1 : ((b > a) ? -1 : 0)));

  useEffect(() => {
    console.log(titles);
    const handleClickOutsideDropdown = (event) => {
      if (curModal === 'Industry' && modal.current && !modal.current.contains(event.target)) onClose();
    };

    document.addEventListener('mousedown', handleClickOutsideDropdown);
    document.addEventListener('touchstart', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
      document.removeEventListener('touchstart', handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    if (token && titles.length === 0) {
      dispatch(loadTitles());
    }
  }, [token]);

  useEffect(() => { // Only Filters Industry
    if (searchQuery.trim().length === 0) {
      setFilteredOptions(titles);
    } else {
      const filteredArr = [];
      titles.forEach((el) => {
        if (el.toLowerCase().includes(searchQuery.toLowerCase())) {
          filteredArr.push(el);
        }
      });
      setFilteredOptions(filteredArr);
    }
  }, [searchQuery, titles]);

  const onItemSelect = (name) => {
    if (selectedIndustries.length >= maxSelection) {
      toast.warning(`There is a limit of ${maxSelection} options`);
      return;
    }
    console.log('[Add topic Component]', selectedIndustries);
    setSelectedIndustries((prevSelected) => [
      ...prevSelected,
      name,
    ]);
  };

  const onItemClear = (name) => {
    setSelectedIndustries((prevSelected) => prevSelected.filter((el) => el !== name));
  };

  return (
    <>
      <Backdrop showBackdrop zIndex={-1} closeModal={onClose} />
      <div className={styles.industryModal} ref={modal}>
        <header>
          <p>{headerText}</p>
          <button type="button" onClick={onClose}>
            <CancelIcon />
          </button>
        </header>
        <p className={styles.ques}>{headerSub}</p>
        {!hideSearch
      && (
      <div className={styles.search}>
        <SearchInput
          placeholder={placeholder}
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
      </div>
      )}
        <div className={styles.options}>
          {selectedIndustries.length !== 0
          && (
            <div className={styles.selected}>
              <p>Selected:</p>
              {selectedIndustries.map((option) => (
                <button
                  className={styles.option}
                  type="button"
                  key={option}
                  onClick={() => {
                    onItemClear(option);
                  }}
                >
                  <p className={styles.active}>
                    {option}
                  </p>
                  <span className={styles.active}>
                    <TickIcon />
                  </span>
                </button>
              ))}
            </div>
          )}
        </div>
        {filteredOptions.length === 0 ? (
          <div className={styles.loader}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={styles.options}>
            <div className={styles.noSelected}>
              {filteredOptions.map((option) => {
                const selected = selectedIndustries.includes(option);
                return (
                  <>
                    {!selected ? (
                      <button
                        className={styles.option}
                        type="button"
                        key={option}
                        onClick={() => {
                          if (!selected) {
                            onItemSelect(option);
                          } else {
                            onItemClear(option);
                          }
                        }}
                      >
                        <p className={selected ? styles.active : ''}>
                          {option}
                        </p>
                        <span className={selected ? styles.active : ''}>
                          <TickIcon />
                        </span>
                      </button>
                    ) : null }
                  </>
                );
              })}
            </div>
          </div>
        )}
        <button
          className={styles.submitBtn}
          type="button"
          onClick={() => {
            onClose();
            onSelect(selectedIndustries);
          }}
        >
          Done
        </button>
      </div>
    </>
  );
};

SelectDropDown.defaultProps = {
  onClose: () => {},
  onSelect: () => {},
  headerText: 'Select Interests',
  headerSub: 'What Interests is your content related to?',
  placeholder: 'Search Interests',
  curModal: '',
  pickedValue: [],
  hideSearch: false,
  maxLimit: 3,
};

SelectDropDown.propTypes = {
  onClose: PropTypes.func,
  pickedValue: PropTypes.array,
  onSelect: PropTypes.func,
  headerText: PropTypes.string,
  headerSub: PropTypes.string,
  placeholder: PropTypes.string,
  curModal: PropTypes.string,
  hideSearch: PropTypes.bool,
  maxLimit: PropTypes.number,
};

export default SelectDropDown;
