import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import DesktopNavBar from './DesktopNavBar';
import TopBar from './WebMobile/TopBar';
import BottomBar from './WebMobile/BottomBar';
import styles from './MainNavBar.module.scss';
import { resetRedirecToBlog } from '../../../../common/data/actions';

const MainNavBar = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const redirectBlog = useSelector((state) => state.main.redirectBlog);
  const user = useSelector((state) => state.account.user);

  useEffect(() => {
    if (user && redirectBlog) {
      router.push('/blog');
      dispatch(resetRedirecToBlog());
    }
  }, [user]);

  return (
    <>
      {/* Desktop */}
      <DesktopNavBar />
      {/* Mobile */}
      <div className={styles.hideOnDesktop}>
        <TopBar />
        <BottomBar />
      </div>
    </>
  );
};

export default MainNavBar;
