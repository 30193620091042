import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import router from 'next/router';
import {
  logOut,
  setFilters,
  switchPalleteColor,
} from '../../../../../common/data/actions';
import ClearIcon from '../../../../../public/icons/svg/cancel.svg';
import SearchIcon from '../../../../../public/icons/svg/search.svg';
import LightNotify from '../../../../../public/icons/svg/light-notify.svg';
import DarkNotify from '../../../../../public/icons/svg/dark-notify.svg';
import LightPerson from '../../../../../public/icons/svg/light-person.svg';
import DarkPerson from '../../../../../public/icons/svg/dark-person.svg';
import LightCalendar from '../../../../../public/icons/svg/light-cal.svg';
import DarkCalendar from '../../../../../public/icons/svg/dark-cal.svg';
import EntreAvatar from '../../../UI/Avatar/EntreAvatar/EntreAvatar';
import ShowSkeleton from '../../../UI/Skeleton/ShowSkeleton';
import GlobalSearch from '../GlobalSearch/GlobalSearch';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import { GlobalSearchContextProvider } from '../../../../contexts/GlobalSearchContext';
import styles from './TopBar.module.scss';
import DmChatStore from '../../../../../common/mobx/stores/DmChatStore';
import Modal from '../../../UI/Modal/Modal';
import PhoneVerification from '../../../Inbox/Messages/PhoneVerification/PhoneVerification';
import newApi from '../../../../../common/api/newApi';

const TopBar = () => {
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const lightMode = useSelector((state) => state.main.lightMode);
  const user = useSelector((state) => state.account.user);
  const [searchInputState = searchQuery, setSearchInputState] = useState();
  const [dropDownId, setDropDownId] = useState('');
  const [activeModal, setActiveModal] = useState('');
  const openChatAfterVerify = useRef(null);

  const messageSupportHandler = async () => {
    if (!user?.verified) {
      openChatAfterVerify.current = true;
      return setActiveModal('phone');
    }
    // used for dev env
    let supportUser = { id: 'm5UcSvMbq8ccQC1pxaDVzTsF7yL2' };
    if (process.env.ENV === 'production') {
      // Production support account
      const supportUserData = await newApi.get('user/username/support');
      supportUser = supportUserData.data;
    }
    if (!supportUser) {
      throw new Error();
    }
    if (window.innerWidth <= 900) {
      sessionStorage.setItem('chatDetails', JSON.stringify({ isChatId: false, profile: supportUser }));
      router.push('/messaging');
    } else {
      await DmChatStore.createChat(null, [supportUser]);
      DmChatStore.setShowQuickChatView(true);
    }
    openChatAfterVerify.current = null;
    return false;
  };

  const profileMenuSection = [
    { label: 'My Profile', path: `/profile/${user && user.username}` },
    { label: 'Billing', path: '/billing' },
    { label: 'Recordings', path: '/recordings', hidden: user && !user.isPro },
    { label: 'Admin', path: '/admin', hidden: user && !user.isAdmin },
    { label: 'Support', action: () => messageSupportHandler() },
    { label: 'Settings', path: '/settings' },
    { label: 'Blog', path: '/blog' },
    { label: 'Help Center', path: 'https://www.entre.link/help' },
    { component: <hr key="hr" />, hideInDrawer: true },
    { label: 'Dark Mode', action: switchPalleteColor(), checkbox: true },
    { component: <hr key="hr2" />, hideInDrawer: true },
    { label: 'Log Out', action: logOut() },
  ].filter((s) => !s.hidden);

  useEffect(() => {
    if (dropDownId === 'search') {
      document.body.classList.add('disable-scroll');
    }
    return () => document.body.classList.remove('disable-scroll');
  }, [dropDownId]);

  const linkClickHandler = (event, section) => {
    event.preventDefault();
    if (section.path) {
      if (
        section.path.startsWith('https')
        || section.path.startsWith('mailto:')
      ) {
        window.open(section.path, '_blank');
      } else {
        router.push(section.path);
      }
    } else if (section.action !== null) {
      dispatch(section.action);
    }
    setDropDownId('');
  };

  const inputChangeHandler = (event) => {
    setSearchInputState(event.target.value);
    setDropDownId('search');
    dispatch(setFilters({ searchQuery: event.target.value }));
  };

  const inputFocusHandler = () => {
    setDropDownId('search');
  };

  const searchHandler = (event, searchIconClicked) => {
    if (searchIconClicked || event.key === 'Enter') {
      setDropDownId('search');
      if (event?.target?.value) {
        dispatch(setFilters({ searchQuery: event.target.value }));
      }
    } else if (event.key === 'Escape') {
      setDropDownId('');
      setSearchInputState('');
    }
  };

  const profileMenuDropDown = profileMenuSection.map((section) => {
    if (section.component) {
      return section.component;
    }
    return (
      <a
        href={section.path}
        role="button"
        tabIndex={0}
        key={section.label}
        onKeyDown={(event) => linkClickHandler(event, section)}
        onClick={(event) => linkClickHandler(event, section)}
      >
        {section.checkbox ? (
          <>
            <span>
              <Checkbox
                value={!lightMode}
                onChange={() => {
                  switchPalleteColor();
                }}
                name="pallete-checkbox"
              />
            </span>
          </>
        ) : null}
        <p>{section.label}</p>
      </a>
    );
  });

  useEffect(() => {
    setDropDownId('');
    setSearchInputState('');
    dispatch(setFilters({ searchQuery: '' }));
  }, [router]);

  const profileDropdown = () => {
    if (dropDownId !== 'menus') {
      setDropDownId('menus');
    } else {
      setDropDownId('');
    }
  };

  useEffect(() => {
    if (user?.verified && openChatAfterVerify.current) {
      setTimeout(() => setActiveModal(''), 2000);
      messageSupportHandler();
    }
  }, [user]);

  return (
    <>
      <div className={styles.navBar}>
        <div className={styles.navBar__items}>

          <div className={styles.globalSearchInput}>
            <button
              className={styles.searchIcon}
              type="button"
              onClick={searchInputState
                ? () => searchHandler(null, true) : null}
            >
              <SearchIcon />
            </button>
            <input
              onChange={inputChangeHandler}
              onFocus={inputFocusHandler}
              value={searchInputState}
              onKeyDown={searchHandler}
              type="search"
              placeholder="Search"
            />
            {searchInputState ? (
              <button
                type="button"
                className={styles.clearIcon}
                onClick={() => {
                  dispatch(setFilters({ searchQuery: '' }));
                  setSearchInputState('');
                  setDropDownId('');
                }}
              >
                <ClearIcon />
              </button>
            ) : null}
            {dropDownId === 'search' && (
            <div className={`${styles.globalSearch}`}>
              <GlobalSearchContextProvider>
                <GlobalSearch />
              </GlobalSearchContextProvider>
            </div>
            )}
          </div>

          <div className={styles.iconsContainer}>
            {lightMode ? (
              <>

                <LightPerson
                  onClick={() => { router.push('/rewards'); }}
                />

                <LightCalendar
                  onClick={() => { router.push('/calendar'); }}
                />

                <LightNotify
                  onClick={() => { router.push('/notifications'); }}
                />

              </>
            ) : (
              <>
                <DarkPerson
                  onClick={() => { router.push('/rewards'); }}
                />

                <DarkCalendar
                  onClick={() => { router.push('/calendar'); }}
                />
                <DarkNotify
                  onClick={() => { router.push('/notifications'); }}
                />
              </>
            )}
            <span className={styles.userIcon}>
              <button
                type="button"
                className={styles.wrapper}
                onClick={() => profileDropdown()}
              >
                {user ? (
                  <EntreAvatar fullName={user?.fullName} src={user?.avatar} />
                ) : (
                  <ShowSkeleton type="navbar" />
                )}
              </button>
              <div
                className={`${styles.dropDownProfile}${
                  dropDownId === 'menus' ? ` ${styles.show}` : ''
                }`}
              >
                {profileMenuDropDown}
              </div>
            </span>
          </div>
        </div>
        <Modal
          isOpen={activeModal === 'phone'}
          onClose={() => setActiveModal('phone')}
          maximumWidth="30rem"
          disableOutsideClick={!user?.verified}
        >
          <PhoneVerification onClose={() => setActiveModal('')} />
        </Modal>
      </div>
    </>
  );
};

export default TopBar;
