import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import clsx from 'clsx';
import Emoji from '../../../../../public/icons/svg/successEmoji.svg';
import styles from './PhoneVerification.module.scss';
import 'react-phone-number-input/style.css';
import CloseIcon from '../../../../../public/icons/svg/cancel.svg';
import { codeValidate, verifyPhoneNumber } from '../../../../../common/data/actions';
import Button from '../../../UI/Button/Button';
import LoadingSpinner from '../../../UI/LoadingSpinner/LoadingSpinner';

const PhoneVerification = ({ onClose }) => {
  const dispatch = useDispatch();
  const [step, setNextStep] = useState(0);
  const [value, setValue] = useState();
  const [validationCode, setValidationCode] = useState();
  const [validatingPhone, setValidatingPhone] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [counter, setCounter] = useState(null);
  const [posting, setPosting] = useState(false);
  const [waitingTimer, setWaitingtimer] = useState(null);
  const inputRef = useRef(null);
  const resendRef = useRef(null);

  function fmtMSS(s) {
    // eslint-disable-next-line no-param-reassign
    const res = (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
    return res;
  }

  const checkIsValidNumber = async (p) => {
    if (isPossiblePhoneNumber(p)) {
      return false;
    }
    return true;
  };

  const checkIsValid = async (p) => {
    if (!p) {
      setValidPhoneNumber(false);
    } else {
      const isInvalid = await checkIsValidNumber(p);
      if (!isInvalid) {
        setPhoneNumberError(null);
      } else {
        setPhoneNumberError(true);
      }
      setValidPhoneNumber(!isInvalid);
      setPhoneNumberError(isInvalid ? 'Phone number not valid. Please retry.' : null);
    }
    setValidatingPhone(false);
  };

  const validateNumber = (p) => {
    setWaitingtimer(false);
    setCounter(0);
    clearTimeout(window.timer);
    window.timer = setTimeout(() => {
      setValidatingPhone(true);
      checkIsValid(p);
    }, 420);
  };

  useEffect(() => {
    if (value === undefined) {
      setPhoneNumberError(null);
      setValidPhoneNumber(null);
    } else {
      validateNumber(value);
      setPhoneNumberError(null);
      setWaitingtimer(false);
      setCounter(null);
    }
  }, [value]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
    if (counter === 0) {
      setWaitingtimer(false);
      setPhoneNumberError(null);
      setValidatingPhone(false);
      inputRef?.current?.focus();
    }
  }, [counter]);

  const handleSubmit = async (type) => {
    const handleDispatch = async () => {
      if (type === 'getCode') {
        return dispatch(verifyPhoneNumber(value));
      }
      return dispatch(codeValidate(validationCode));
    };
    setPosting(true);
    const resp = await handleDispatch();
    if (resp.data.error) {
      setPhoneNumberError(resp.data.error);
      setValidPhoneNumber(false);
      if (resp.data.wait) {
        setCounter(resp.data.wait * 60);
        setWaitingtimer(true);
      }
      if (resp.data.error === 'Code has already been sent' && step === 0) {
        setNextStep(1);
      }
      return setPosting(false);
    }
    if (resp.data.wait) {
      setCounter(resp.data.wait * 60);
      setWaitingtimer(true);
    }
    if (resp.data && !resendRef.current) {
      setNextStep(step + 1);
    }
    resendRef.current = null;
    return setPosting(false);
  };

  const handleCodeChange = (val) => {
    setValidationCode(val);
  };

  // Move focus to END of input field on button click
  const moveCursorToEnd = () => {
    let input = null;
    if (document && document.readyState === 'complete') {
      input = document.getElementById('phoneNr');
    }
    const end = input?.value?.length;
    input.setSelectionRange(end, end);
    input.focus();
  };

  return (
    <div className={styles.content}>
      {!posting ? (
        <>
          <div className={styles.closeIcon}>
            <button
              type="button"
              onClick={() => onClose()}
            >
              <CloseIcon />
            </button>
          </div>
          <div className={styles.stepWrapper}>

            <div className={styles.stepContainer}>
              {step === 0 ? (
                <>
                  <span className={styles.title}>Phone Verification Required</span>
                  <span className={styles.subTitle}>
                    To protect our users from spam and to start
                    your first conversation, we need you to verify
                    your phone number.
                  </span>
                  <div className={styles.input}>
                    <PhoneInput
                      id="phoneNr"
                      ref={inputRef}
                      international
                      defaultCountry="US"
                      value={value}
                      onChange={setValue}
                      onClick={moveCursorToEnd}
                    />
                  </div>
                  {phoneNumberError ? (
                    <div className={styles.notValid}>
                      <p>{phoneNumberError}</p>
                    </div>
                  ) : null }
                  { waitingTimer && counter > 0 ? (
                    <p className={styles.notValid}>
                      Please wait
                      {' '}
                      {fmtMSS(counter)}
                      {' '}
                      to re-send.
                    </p>
                  ) : null}
                  <div className={styles.actionBtns}>
                    <Button
                      size="large"
                      isDisabled={!validPhoneNumber || validatingPhone || waitingTimer}
                      action={() => handleSubmit('getCode')}
                    >
                      {posting ? '...Sending' : 'Send me the code via SMS'}
                    </Button>
                  </div>
                </>
              ) : null}
            </div>

            <div className={styles.stepContainer}>
              {step === 1 ? (
                <>
                  <span className={styles.title}>Enter your 6-digit Code</span>
                  <span className={styles.subTitle}>
                    Please input the 6-digit code you received via
                    SMS to complete your account verification.
                  </span>
                  <div className={styles.input}>
                    <ReactCodeInput
                      className={styles.codeVerification}
                      onChange={handleCodeChange}
                      type="text"
                      values={validationCode}
                      fields={6}
                    />
                  </div>
                  {phoneNumberError ? (
                    <div className={styles.notValid}>
                      <p>{phoneNumberError}</p>
                    </div>
                  ) : null }
                  <div className={styles.notCode}>
                    {!phoneNumberError && counter > 0 ? (
                      <span>
                        Didn’t get a code ?
                      </span>
                    ) : null}
                    <button
                      className={clsx({
                        [styles.button]: true,
                        [styles.isDisabled]: counter && counter > 0,
                      })}
                      type="button"
                      disabled={counter && counter > 0}
                      onClick={() => {
                        resendRef.current = true;
                        handleSubmit('getCode');
                      }}
                    >
                      { counter && counter > 0 ? (
                        <p>
                          Please wait
                          {' '}
                          {fmtMSS(counter)}
                          {' '}
                          to re-send.
                        </p>
                      )
                        : (
                          <p>
                            Please re-send me a code.
                          </p>
                        )}
                    </button>
                  </div>
                  <div className={styles.actionBtns}>
                    <Button
                      size="large"
                      isDisabled={validationCode?.length !== 6}
                      action={() => handleSubmit('validateCode')}
                    >
                      {posting ? '...Sending' : 'Submit Code'}
                    </Button>
                  </div>
                </>
              ) : null}
            </div>

            <div className={styles.stepContainer}>
              {step === 2 ? (
                <>
                  <Emoji />
                  <span className={styles.title}>Your Account is verified!</span>
                  <span className={styles.subTitle_success}>
                    Thank you for taking the time to complete
                    the verification process. Now that you are
                    verified you can connect with and message other users in the community.
                  </span>
                </>
              ) : null}
            </div>

          </div>
        </>
      ) : (
        <>
          <div className={styles.stepContainer}>
            <div className={styles.loader}>
              <LoadingSpinner size="big" />
              <p>Please Wait...</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

PhoneVerification.defaultProps = {
  onClose: () => { },
};

PhoneVerification.propTypes = {
  onClose: PropTypes.func,
};

export default PhoneVerification;
