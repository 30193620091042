import React, {
  createContext, useEffect, useState, useRef,
} from 'react';
import algoliasearch from 'algoliasearch/lite';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import newApi from '../../common/api/newApi';
import useDebounce from '../hooks/useDebounce';
import ShowSkeleton from '../components/UI/Skeleton/ShowSkeleton';
// import { setFilters } from '../../common/data/actions';

const GlobalSearchContext = createContext(null);

export const GlobalSearchContextProvider = ({ children }) => {
  const user = useSelector((state) => state.account.user);
  const [algoliaReady, setAlgoliaReady] = useState(false);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const [userItems, setUserItems] = useState([]);
  const [contentItems, setContentItems] = useState([]);
  const [eventItems, setEventItems] = useState([]);
  const [jobItems, setJobItems] = useState([]);
  const [dealItems, setDealItems] = useState([]);
  const [index, setIndex] = useState(0);
  const [algoliaApiKey, setAlgoliaApiId] = useState(process.env.ALGOLIA_ID_NEW);
  const debouncedSearchTerm = useDebounce(searchQuery, 500);
  const hasMoreRef = useRef(false);
  const [loading, setLoading] = useState(true);
  const [currTab, setCurrTab] = useState();
  const [filterString, setFilterString] = useState('');
  // Filter states
  const [industry, setIndustry] = useState([]);
  const [location, setLocation] = useState('');
  const [titles, setTitles] = useState([]);

  const MyLoader = () => (
    <ShowSkeleton type="globalSearch" />
  );

  const resetData = () => {
    setUserItems([]);
    setJobItems([]);
    setEventItems([]);
    setContentItems([]);
    setDealItems([]);
  };

  const queriesManager = (value, page) => {
    let queries = [];
    if (index === 0) {
      queries = [
        {
          indexName: 'users',
          query: value,
          params: {
            hitsPerPage: 2,
            filters: user.industry.map((item) => `industry: "${item}"`).join(' OR '),
          },
        },
        {
          indexName: 'posts',
          query: value,
          params: {
            hitsPerPage: 2,
          },
        },
        {
          indexName: 'events',
          query: value,
          params: {
            hitsPerPage: 2,
            filters: user.industry.map((item) => `industry: "${item}"`).join(' OR '),
          },
        },
        {
          indexName: 'jobs',
          query: value,
          params: {
            hitsPerPage: 2,
            filters: user.industry.map((item) => `industry: "${item}"`).join(' OR '),

          },
        },
        {
          indexName: 'deals',
          query: value,
          params: {
            hitsPerPage: 2,
          },
        },
      ];
    } else if (index === 1) {
      queries = [
        {
          indexName: 'users',
          query: value,
          params: {
            hitsPerPage: 10,
            page,
          },
        },
      ];
    } else if (index === 2) {
      queries = [
        {
          indexName: 'posts',
          query: value,
          params: {
            hitsPerPage: 10,
            page,
          },
        },
      ];
    } else if (index === 3) {
      queries = [
        {
          indexName: 'events',
          query: value,
          params: {
            hitsPerPage: 10,
            page,
          },
        },
      ];
    } else if (index === 4) {
      queries = [
        {
          indexName: 'jobs',
          query: value,
          params: {
            hitsPerPage: 10,
            page,
          },
        },
      ];
    } else if (index === 5) {
      queries = [
        {
          indexName: 'deals',
          query: value,
          params: {
            hitsPerPage: 10,
            page,
          },
        },
      ];
    }
    return queries;
  };

  const handleSearch = async (
    value = debouncedSearchTerm,
    filter = '',
    page = 0,
    setNextPage = null,
  ) => {
    setLoading(true);
    const queries = queriesManager(value || '', page);
    if (page === 0) {
      resetData();
    }
    if (!filter && index === 1 && debouncedSearchTerm === '') {
      queries[0].filters = 'isAdmin=0';
    } else if (index !== 0 && filter) {
      queries[0].filters = filter;
    }

    if (algoliaReady) {
      const client = await algoliasearch(
        process.env.ALGOLIA_APP_ID,
        algoliaApiKey,
      );
      await client.multipleQueries(queries).then(({ results }) => {
        console.log('Fetching Results from global search: ', results);
        const type = [
          results[0]?.index,
          results[1]?.index,
          results[2]?.index,
          results[3]?.index,
          results[4]?.index,
        ];

        const userIdx = type.indexOf('users');
        const contentIdx = type.indexOf('posts');
        const eventIdx = type.indexOf('events');
        const jobIdx = type.indexOf('jobs');
        const dealIdx = type.indexOf('deals');

        const fetchData = (dataIdx, setter) => {
          if (
            dataIdx > -1
            && results
            && results[dataIdx]
            && results[dataIdx].hits
            && results[dataIdx].hits.length
          ) {
            hasMoreRef.current = results[dataIdx].nbPages > results[dataIdx].page;
            setLoading(false);
            const modifiedResponse = results[dataIdx].hits
              .map((item) => (item.id ? item : { ...item, id: item.objectID }));
            setter((currSearchList) => (results[dataIdx].page === 0
              ? modifiedResponse
              : [...currSearchList, ...modifiedResponse]));
            if (setNextPage && hasMoreRef.current) {
              setNextPage(results[dataIdx].page + 1);
            }
            console.log('length is: ', userItems.length);
          } else {
            setLoading(false);
            hasMoreRef.current = false;
          }
        };

        fetchData(userIdx, setUserItems);
        fetchData(contentIdx, setContentItems);
        fetchData(eventIdx, setEventItems);
        fetchData(jobIdx, setJobItems);
        fetchData(dealIdx, setDealItems);
      }).catch((error) => {
        console.error('error fetching data is: ', error);
      });
    }
  };

  const resetFilter = () => {
    setIndustry([]);
    setTitles([]);
    setLocation('');
  };

  useEffect(() => {
    const getFilterParams = () => {
      let filter = '';
      if (location) {
        filter = `location.city: "${location}"`;
      }
      if (titles.length > 0) {
        filter += `${filter.length > 0 ? ' AND ' : ''} titleName: "${titles[0]}"`;
      }
      if (industry.length > 0) {
        filter += `${filter.length > 0 ? ' AND ' : ''} industry: "${industry[0]}"`;
      }
      return filter;
    };
    setFilterString(getFilterParams());
  }, [location, titles, industry]);

  useEffect(() => {
    const fetchSearchToken = async () => {
      const res = await newApi.get('user/connections-search-token');
      if (res && res.data) {
        console.log('Fetching Search Token', res.data.key);
        if (index === 1) {
          setAlgoliaApiId(res.data.key);
        } else {
          setAlgoliaApiId(process.env.ALGOLIA_ID_NEW);
        }
        setAlgoliaReady(true);
      }
    };
    if (user && !algoliaReady) {
      fetchSearchToken();
    }
  }, []);

  useEffect(() => {
    resetFilter();
  }, [index]);

  useEffect(() => {
    if (algoliaReady) {
      handleSearch(debouncedSearchTerm, filterString);
    }
    if (index !== 1) {
      setAlgoliaApiId(process.env.ALGOLIA_ID_NEW);
    }
    return null;
  }, [algoliaReady, index, debouncedSearchTerm, filterString]);

  return (
    <GlobalSearchContext.Provider
      value={{
        userItems,
        contentItems,
        eventItems,
        dealItems,
        jobItems,
        MyLoader,
        loading,
        setIndex,
        index,
        currTab,
        setCurrTab,
        hasMoreRef,
        handleSearch,
        debouncedSearchTerm,
        setTitles,
        setIndustry,
        setLocation,
        industry,
        titles,
        location,
        resetFilter,
        filterString,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
};

GlobalSearchContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default GlobalSearchContext;
