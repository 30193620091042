/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { useRouter } from 'next/router';
import styles from './GlobalSeach.module.scss';
import GlobalSearchContext from '../../../../contexts/GlobalSearchContext';

const EventSearch = ({ setItem }) => {
  const {
    eventItems, loading, MyLoader, hasMoreRef, index, handleSearch,
    debouncedSearchTerm, filterString,
  } = useContext(GlobalSearchContext);
  const [initialLoad, setInitialLoad] = useState(true);
  const [nextPage, setNextPage] = useState(1);
  const router = useRouter();

  useEffect(() => {
    console.log('eventItems updated', eventItems);
  }, [eventItems]);
  const goToPost = (id) => {
    router.push('/event/[id]', `/event/${id}`, { shallow: true });
  };

  const EventSearchCard = ({ detail }) => (
    <div className={styles.ContentSearch} onClick={() => goToPost(detail.id)} role="presentation">
      <div className={styles.content}>
        <div className={styles.text}>
          <h4 className={styles.eventTitle}>{detail.title}</h4>
          <div className={styles.eventDate}>
            <p>{moment(detail.startDateTime).format('MMMM DD, yyyy')}</p>
          </div>
        </div>
        {detail.image && (
        <div className={styles.image}>
          <img className={styles.image} alt="Jason Smith" src={detail.image} />
        </div>
        )}

      </div>
    </div>
  );

  const fetchMore = () => {
    setInitialLoad(false);
    handleSearch(debouncedSearchTerm, filterString, nextPage, setNextPage);
  };

  const renderItem = (items) => {
    const content = index === 0 ? (
      items.map((job) => <EventSearchCard key={job.id} detail={job} />)
    ) : (
      <InfiniteScroll
        pageStart={0}
        initialLoad={initialLoad}
        hasMore={hasMoreRef.current}
        loadMore={fetchMore}
        useWindow={false}
      >
        <>
          {items.map((event) => (
            <EventSearchCard key={event.id} detail={event} />
          ))}
          {loading && <MyLoader />}
        </>
      </InfiniteScroll>
    );
    if (items.length === 0 && !loading && index !== 0) {
      return 'No result found';
    }
    return items.length === 0 && loading ? <MyLoader /> : content;
  };

  return (
    eventItems.length !== 0
      ? (
        <div className={styles.EventSearch}>
          {index === 0 && eventItems.length !== 0
      && (
      <header className={styles.GlobalSearch__header}>
        <h4>Events</h4>
        <button type="button" className={styles.btnLink} onClick={setItem}>View all</button>

      </header>
      )}

          <div className={styles.section}>
            {renderItem(eventItems)}
          </div>
        </div>
      ) : null
  );
};

export default EventSearch;
