/* eslint-disable no-alert, no-console, linebreak-style */
import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { InboxConnectionsContext } from '../../contexts/InboxConnectionsContext';
import styles from './NotificationIndicator.module.scss';

const NotificationIndicator = ({ title, show, indicatorStyle }) => {
  const { counter, allNofications, supportCount } = useContext(InboxConnectionsContext) || {};
  const router = useRouter();
  const requiredRoute = '/inbox';

  useEffect(() => {
    console.debug(router.route);
  }, []);

  const getValue = (value) => {
    let messageText = null;
    if (value === 'Notifications' && counter.notification > 0 && router.route === requiredRoute) {
      messageText = counter.notification;
    } if (value === 'Connections' && counter.connection > 0 && router.route === requiredRoute) {
      messageText = counter.connection;
    } else if (value === 'Messages' && counter.messages > 0 && (router.route === requiredRoute || show)) {
      messageText = counter.messages;
    } else if (value === 'Support' && supportCount > 0 && (router.route === requiredRoute || show)) {
      messageText = supportCount;
    } else if (value === 'all' && allNofications > 0) {
      messageText = allNofications;
    }
    if (messageText > 99) {
      return '99+';
    }
    return messageText;
  };

  return (
    getValue(title) && (
      <div className={clsx(styles.notification_indicator, indicatorStyle)}>
        <p>{getValue(title)}</p>
      </div>
    )
  );
};

NotificationIndicator.defaultProps = {
  title: '',
  show: false,
  indicatorStyle: {},
};

NotificationIndicator.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  indicatorStyle: PropTypes.object,
};

export default NotificationIndicator;
